import { Component } from "@angular/core";

@Component({
  selector: "ngx-footer",
  styleUrls: ["./footer.component.scss"],
  template: `
    <span class="created-by"
      >Created with <span style="color: red">♥</span> by
      <b><a href="https://www.baianat.com/" target="_blank">Baianat</a></b>
      {{ year }}</span
    >
    <div class="socials">
      <a
        href="https://www.facebook.com/baianat/"
        target="_blank"
        class="ion ion-social-facebook"
      ></a>
    </div>
  `,
})
export class FooterComponent {
  year = new Date().getFullYear();
}
