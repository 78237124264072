import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Timestamp: any;
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};



export type JobApplicant = {
  __typename?: 'JobApplicant';
  id: Scalars['ID'];
  user: User;
  job: Job;
  status: JobApplicantStatusEnum;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export enum JobApplicantStatusEnum {
  Hired = 'HIRED',
  Pended = 'PENDED',
  Refused = 'REFUSED'
}


export type Review = {
  __typename?: 'Review';
  id: Scalars['ID'];
  reviewer: User;
  rate: Scalars['Float'];
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  /** Query Only When necessary */
  teacher?: Maybe<User>;
  /** Query Only When necessary */
  school?: Maybe<School>;
};

export type Teacher = {
  __typename?: 'Teacher';
  teacherId: Scalars['ID'];
  experience: Scalars['Float'];
  grades: Array<EducationalGradesEnum>;
  curriculums: Array<Curriculum>;
  cv: Scalars['String'];
  idDocument: Scalars['String'];
  certificates: Array<Scalars['String']>;
  user: User;
  subjects: Array<Subject>;
  isAvailable: Scalars['Boolean'];
  avgRate: Scalars['Float'];
  noOfReviews: Scalars['Float'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  /** Query Only When necessary */
  reviewed: Scalars['Boolean'];
};

export enum EducationalGradesEnum {
  Kindergarten = 'KINDERGARTEN',
  Primary = 'PRIMARY',
  Intermediate = 'INTERMEDIATE',
  Secondary = 'SECONDARY'
}

export type Subject = {
  __typename?: 'Subject';
  id: Scalars['ID'];
  teachers?: Maybe<Array<Maybe<Teacher>>>;
  schools?: Maybe<Array<Maybe<School>>>;
  jobs?: Maybe<Array<Maybe<Job>>>;
  enName?: Maybe<Scalars['String']>;
  arName?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
  arDescription?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export type Job = {
  __typename?: 'Job';
  id: Scalars['ID'];
  grade: EducationalGradesEnum;
  gender: JobGenderEnum;
  minExperience: Scalars['Float'];
  maxExperience: Scalars['Float'];
  requiredApplicantsNumber: Scalars['Float'];
  status: JobStatusEnum;
  completeReason?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  requirements: Array<Scalars['String']>;
  startDate?: Maybe<Scalars['Timestamp']>;
  endDate?: Maybe<Scalars['Timestamp']>;
  salary?: Maybe<Scalars['Float']>;
  school: School;
  reports: Array<Report>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  numberOfDays: Scalars['Int'];
  total: Scalars['Int'];
  /** Query Only When necessary */
  numberOfApplicants: Scalars['Int'];
  /** Query Only When necessary */
  subject: Subject;
  /** Query Only When necessary */
  curriculum: Curriculum;
  /** Query Only When necessary */
  appliedAt?: Maybe<Scalars['Timestamp']>;
  applicantsAllowedCities: Array<City>;
};

export enum JobGenderEnum {
  Male = 'MALE',
  Female = 'FEMALE',
  All = 'ALL'
}

export enum JobStatusEnum {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Hired = 'HIRED',
  Completed = 'COMPLETED'
}

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID'];
  reporterId: Scalars['ID'];
  reportedId?: Maybe<Scalars['ID']>;
  jobId?: Maybe<Scalars['ID']>;
  content: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  reporter: User;
  job?: Maybe<Job>;
  reported?: Maybe<User>;
};

export type LocationType = {
  __typename?: 'LocationType';
  type: Scalars['String'];
  /** [long,lat] */
  coordinates: Array<Scalars['Float']>;
};

export type LastLoginDetails = {
  __typename?: 'LastLoginDetails';
  lastLoginAt: Scalars['Timestamp'];
  lastLoginDevice: DeviceEnum;
  platformDetails?: Maybe<Scalars['JSON']>;
};

export enum DeviceEnum {
  Desktop = 'DESKTOP',
  Ios = 'IOS',
  Android = 'ANDROID'
}


export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  type: NotificationTypeEnum;
  thumbnail?: Maybe<Scalars['String']>;
  enTitle: Scalars['String'];
  arTitle: Scalars['String'];
  enBody: Scalars['String'];
  arBody: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  localeTitle: Scalars['String'];
  localeBody: Scalars['String'];
  parent?: Maybe<NotificationParentUnion>;
  sender?: Maybe<User>;
  receivers: Array<Maybe<NotificationReceiver>>;
};

export enum NotificationTypeEnum {
  AdminPublic = 'ADMIN_PUBLIC',
  NewContactMessage = 'NEW_CONTACT_MESSAGE',
  StartTyping = 'START_TYPING',
  OneDayExpiration = 'ONE_DAY_EXPIRATION',
  TenDaysExpiration = 'TEN_DAYS_EXPIRATION',
  HaveNoSubscription = 'HAVE_NO_SUBSCRIPTION',
  SubscriptionExpired = 'SUBSCRIPTION_EXPIRED',
  UserUpdateStatus = 'USER_UPDATE_STATUS',
  NewJobPosted = 'NEW_JOB_POSTED',
  NewFollower = 'NEW_FOLLOWER',
  Hired = 'HIRED',
  Blocked = 'BLOCKED',
  Unblocked = 'UNBLOCKED',
  NewJopApplicant = 'NEW_JOP_APPLICANT',
  DocumentUpdateStatus = 'DOCUMENT_UPDATE_STATUS',
  NewJobUpdate = 'NEW_JOB_UPDATE',
  SubscriptionActivated = 'SUBSCRIPTION_ACTIVATED',
  SubscriptionFailed = 'SUBSCRIPTION_FAILED',
  NewReport = 'NEW_REPORT',
  NewTeacher = 'NEW_TEACHER',
  NewSchool = 'NEW_SCHOOL'
}

export type NotificationParentUnion = User | Transaction | DocumentUpdate | NotExistRecord | Job | JobApplicant | Chat;

export type NotExistRecord = {
  __typename?: 'NotExistRecord';
  notExistRecord: Scalars['Boolean'];
};

export type NotificationManager = {
  __typename?: 'NotificationManager';
  NEW_CONTACT_MESSAGE?: Maybe<Scalars['Boolean']>;
  NEW_FOLLOWER?: Maybe<Scalars['Boolean']>;
  NEW_JOB_POSTED?: Maybe<Scalars['Boolean']>;
  NEW_JOP_APPLICANT?: Maybe<Scalars['Boolean']>;
};

export type NotificationReceiver = {
  __typename?: 'NotificationReceiver';
  receiver: User;
  seenAt?: Maybe<Scalars['Timestamp']>;
};

export type SecurityGroup = {
  __typename?: 'SecurityGroup';
  id: Scalars['ID'];
  groupName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  permissions: Array<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  relativeDiskDestination: Scalars['String'];
  name: Scalars['String'];
  encoding: Scalars['String'];
  mimetype: Scalars['String'];
  sizeInBytes: Scalars['Float'];
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  chatId: Scalars['ID'];
  chat: Chat;
  sender: User;
  senderId: Scalars['ID'];
  receiver: User;
  receiverId: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
};

export type Chat = {
  __typename?: 'Chat';
  id: Scalars['ID'];
  messages?: Maybe<Array<Maybe<Message>>>;
  userChats?: Maybe<Array<Maybe<UserChat>>>;
  createdAt: Scalars['Timestamp'];
  updatedAt?: Maybe<Scalars['Timestamp']>;
  unseenMessagesCount?: Maybe<Scalars['Int']>;
};

export type UserChat = {
  __typename?: 'UserChat';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  owner?: Maybe<User>;
  recipient?: Maybe<User>;
  chat?: Maybe<Chat>;
  chatId: Scalars['ID'];
  status: ChatStatusEnum;
  recipientId: Scalars['ID'];
  report?: Maybe<Scalars['String']>;
  lastOpened?: Maybe<Scalars['Timestamp']>;
};

export enum ChatStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Favorite = 'FAVORITE',
  Deleted = 'DELETED'
}

export type Card = {
  __typename?: 'Card';
  id: Scalars['ID'];
  expMonth: Scalars['String'];
  expYear: Scalars['String'];
  last4: Scalars['String'];
  brand: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  id: Scalars['ID'];
  arDescription?: Maybe<Array<Maybe<Scalars['String']>>>;
  enDescription?: Maybe<Array<Maybe<Scalars['String']>>>;
  arName: Scalars['String'];
  enName: Scalars['String'];
  slug: Scalars['String'];
  price: Scalars['Float'];
  numberOfDays: Scalars['Float'];
  inAppPurchaseId: Scalars['String'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
};

export type PaymentUseCase = {
  __typename?: 'PaymentUseCase';
  itemSnapShot: SubscriptionPlan;
  paymentMethod: PaymentMethodEnum;
  paymentStatus: PaymentStatusEnum;
  failureReason?: Maybe<PaymentFailureReasonEnum>;
  gatewayOrderId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export enum PaymentMethodEnum {
  FreeTrail = 'FREE_TRAIL',
  Card = 'CARD',
  ApplePurchase = 'APPLE_PURCHASE'
}

export enum PaymentStatusEnum {
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED',
  Pended = 'PENDED',
  Cancelled = 'CANCELLED'
}

export enum PaymentFailureReasonEnum {
  UnknownError = 'UNKNOWN_ERROR',
  ExpiredCard = 'EXPIRED_CARD',
  BankProblem = 'BANK_PROBLEM',
  InsufficientCardFunds = 'INSUFFICIENT_CARD_FUNDS',
  InsufficientWalletFunds = 'INSUFFICIENT_WALLET_FUNDS',
  CallbackPermissionIssue = 'CALLBACK_PERMISSION_ISSUE',
  AuthenticateError = 'AUTHENTICATE_ERROR',
  PaymentKeyError = 'PAYMENT_KEY_ERROR',
  OrderCreationError = 'ORDER_CREATION_ERROR'
}

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['ID'];
  user?: Maybe<User>;
  amount: Scalars['Float'];
  startsAt?: Maybe<Scalars['Timestamp']>;
  expiresIn?: Maybe<Scalars['Timestamp']>;
  transactionType: TransactionTypeEnum;
  payment: PaymentUseCase;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  card?: Maybe<Card>;
};

export enum TransactionTypeEnum {
  Payment = 'PAYMENT'
}

export type DocumentUpdate = {
  __typename?: 'DocumentUpdate';
  id: Scalars['ID'];
  user: User;
  documents: Array<Scalars['String']>;
  status: DocumentStatusEnum;
  documentType: DocumentTypeEnum;
  updateReason: Scalars['String'];
  rejectionReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  createdAt?: Maybe<Scalars['Timestamp']>;
};

export enum DocumentStatusEnum {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Pending = 'PENDING'
}

export enum DocumentTypeEnum {
  Cv = 'CV',
  IdDocument = 'ID_DOCUMENT',
  Certificates = 'CERTIFICATES'
}

export type City = {
  __typename?: 'City';
  id: Scalars['ID'];
  name_ar: Scalars['String'];
  name_en: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  bio?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  rejectionReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  status: StatusEnum;
  isEmailVerified: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  unverifiedPhone?: Maybe<Scalars['String']>;
  gender: GenderEnum;
  birthDate?: Maybe<Scalars['Timestamp']>;
  role: UserRoleEnum;
  arAddress?: Maybe<Scalars['String']>;
  enAddress?: Maybe<Scalars['String']>;
  location?: Maybe<LocationType>;
  profilePicture?: Maybe<Scalars['String']>;
  isBlocked: Scalars['Boolean'];
  favLang: LangEnum;
  securityGroup?: Maybe<SecurityGroup>;
  notificationManager: NotificationManager;
  token?: Maybe<Scalars['String']>;
  lastLoginDetails?: Maybe<LastLoginDetails>;
  school?: Maybe<School>;
  teacher?: Maybe<Teacher>;
  opinions: Array<Review>;
  reviews: Array<Review>;
  reports: Array<Report>;
  cards: Array<Card>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  /** Query Only When necessary */
  isComplete: Scalars['Boolean'];
  /** this filed compute value in km in sum queries like Teacher that might based on providing lat and long to compute distance this filed will have a value based on the difference between provided point and the result other wise will be based on current user location */
  awayFromCurrentUserBy?: Maybe<Scalars['Float']>;
  /** Query Only When necessary */
  subscriptionStatus?: Maybe<SubscriptionStatusEnum>;
  /** Query Only When necessary */
  saved: Scalars['Boolean'];
  city?: Maybe<City>;
};

export enum StatusEnum {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  NotCompleted = 'NOT_COMPLETED'
}

export enum GenderEnum {
  Male = 'MALE',
  Female = 'FEMALE'
}

export enum UserRoleEnum {
  Superadmin = 'SUPERADMIN',
  Teacher = 'TEACHER',
  Schooladmin = 'SCHOOLADMIN'
}

export enum LangEnum {
  En = 'EN',
  Ar = 'AR'
}

export enum SubscriptionStatusEnum {
  FreeEnabled = 'FREE_ENABLED',
  Bended = 'BENDED',
  Active = 'ACTIVE',
  FutureBended = 'FUTURE_BENDED',
  Expired = 'EXPIRED'
}

export type School = {
  __typename?: 'School';
  id: Scalars['ID'];
  curriculums?: Maybe<Array<Maybe<Curriculum>>>;
  subjects?: Maybe<Array<Maybe<Subject>>>;
  name: Scalars['String'];
  schoolAdminId: Scalars['ID'];
  phone: Scalars['String'];
  landlineNumber: Scalars['String'];
  email: Scalars['String'];
  schoolType: SchoolTypeEnum;
  schoolInfo?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  grades?: Maybe<Array<Maybe<EducationalGradesEnum>>>;
  gender?: Maybe<SchoolGenderEnum>;
  certificates?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<LocationType>;
  arAddress?: Maybe<Scalars['String']>;
  avgRate: Scalars['Float'];
  noOfReviews: Scalars['Float'];
  enAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  updatedAt: Scalars['Timestamp'];
  jobs?: Maybe<Array<Maybe<Job>>>;
  /** Query Only When necessary */
  user: User;
  /** Query Only When necessary */
  awayFromCurrentUserBy: Scalars['Float'];
  /** Query Only When necessary */
  followed: Scalars['Boolean'];
  /** Query Only When necessary */
  numberOfJobs: Scalars['Int'];
  /** Query Only When necessary */
  reviewed: Scalars['Boolean'];
  city?: Maybe<City>;
};

export enum SchoolTypeEnum {
  Private = 'PRIVATE',
  International = 'INTERNATIONAL',
  Government = 'GOVERNMENT'
}

export enum SchoolGenderEnum {
  Allboys = 'ALLBOYS',
  Allgirls = 'ALLGIRLS',
  Mixed = 'MIXED'
}

export type Curriculum = {
  __typename?: 'Curriculum';
  id: Scalars['ID'];
  schools?: Maybe<Array<Maybe<School>>>;
  jobs?: Maybe<Array<Maybe<Job>>>;
  teachers?: Maybe<Array<Maybe<Teacher>>>;
  enName?: Maybe<Scalars['String']>;
  arName?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
  arDescription?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  page: Scalars['Int'];
  limit: Scalars['Int'];
  hasNext: Scalars['Boolean'];
};

export type AppConfiguration = {
  __typename?: 'AppConfiguration';
  id: Scalars['ID'];
  key: Scalars['String'];
  value: Scalars['String'];
  displayAs: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  reason: Scalars['String'];
  message: Scalars['String'];
  isResolved: Scalars['String'];
  createdAt?: Maybe<Scalars['Timestamp']>;
  user: User;
};

export type Faq = {
  __typename?: 'Faq';
  id: Scalars['ID'];
  enQuestion: Scalars['String'];
  arQuestion: Scalars['String'];
  enAnswer: Scalars['String'];
  arAnswer: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type GqlStringResponse = {
  __typename?: 'GqlStringResponse';
  data?: Maybe<Scalars['String']>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlBooleanResponse = {
  __typename?: 'GqlBooleanResponse';
  data?: Maybe<Scalars['Boolean']>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlNumberResponse = {
  __typename?: 'GqlNumberResponse';
  data?: Maybe<Scalars['Float']>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlBasicResponse = {
  __typename?: 'GqlBasicResponse';
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlFileResponse = {
  __typename?: 'GqlFileResponse';
  data?: Maybe<File>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlUserResponse = {
  __typename?: 'GqlUserResponse';
  data?: Maybe<User>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlUsersPagination = {
  __typename?: 'GqlUsersPagination';
  items?: Maybe<Array<Maybe<User>>>;
  pageInfo: PageInfo;
};

export type GqlUsersResponse = {
  __typename?: 'GqlUsersResponse';
  data?: Maybe<GqlUsersPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlUsersArrayResponse = {
  __typename?: 'GqlUsersArrayResponse';
  data?: Maybe<Array<Maybe<User>>>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlCityResponse = {
  __typename?: 'GqlCityResponse';
  data?: Maybe<City>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlCitysArrayResponse = {
  __typename?: 'GqlCitysArrayResponse';
  data?: Maybe<Array<Maybe<City>>>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlSecurityGroupResponse = {
  __typename?: 'GqlSecurityGroupResponse';
  data?: Maybe<SecurityGroup>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlSecurityGroupsArrayResponse = {
  __typename?: 'GqlSecurityGroupsArrayResponse';
  data?: Maybe<Array<Maybe<SecurityGroup>>>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlStringsArrayResponse = {
  __typename?: 'GqlStringsArrayResponse';
  data?: Maybe<Array<Maybe<Scalars['String']>>>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlNotificationResponse = {
  __typename?: 'GqlNotificationResponse';
  data?: Maybe<Notification>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlNotificationsPagination = {
  __typename?: 'GqlNotificationsPagination';
  items?: Maybe<Array<Maybe<Notification>>>;
  pageInfo: PageInfo;
};

export type GqlNotificationsResponse = {
  __typename?: 'GqlNotificationsResponse';
  data?: Maybe<GqlNotificationsPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlSubjectResponse = {
  __typename?: 'GqlSubjectResponse';
  data?: Maybe<Subject>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlSubjectsArrayResponse = {
  __typename?: 'GqlSubjectsArrayResponse';
  data?: Maybe<Array<Maybe<Subject>>>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlCurriculumResponse = {
  __typename?: 'GqlCurriculumResponse';
  data?: Maybe<Curriculum>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlCurriculumsArrayResponse = {
  __typename?: 'GqlCurriculumsArrayResponse';
  data?: Maybe<Array<Maybe<Curriculum>>>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlTeacherResponse = {
  __typename?: 'GqlTeacherResponse';
  data?: Maybe<Teacher>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlTeachersPagination = {
  __typename?: 'GqlTeachersPagination';
  items?: Maybe<Array<Maybe<Teacher>>>;
  pageInfo: PageInfo;
};

export type GqlSchoolResponse = {
  __typename?: 'GqlSchoolResponse';
  data?: Maybe<School>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlSchoolsPagination = {
  __typename?: 'GqlSchoolsPagination';
  items?: Maybe<Array<Maybe<School>>>;
  pageInfo: PageInfo;
};

export type GqlSchoolsResponse = {
  __typename?: 'GqlSchoolsResponse';
  data?: Maybe<GqlSchoolsPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlAppConfigurationResponse = {
  __typename?: 'GqlAppConfigurationResponse';
  data?: Maybe<AppConfiguration>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlAppConfigurationsArrayResponse = {
  __typename?: 'GqlAppConfigurationsArrayResponse';
  data?: Maybe<Array<Maybe<AppConfiguration>>>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlDocumentUpdateResponse = {
  __typename?: 'GqlDocumentUpdateResponse';
  data?: Maybe<DocumentUpdate>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlDocumentUpdatesPagination = {
  __typename?: 'GqlDocumentUpdatesPagination';
  items?: Maybe<Array<Maybe<DocumentUpdate>>>;
  pageInfo: PageInfo;
};

export type GqlDocumentUpdatesResponse = {
  __typename?: 'GqlDocumentUpdatesResponse';
  data?: Maybe<GqlDocumentUpdatesPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlJobResponse = {
  __typename?: 'GqlJobResponse';
  data?: Maybe<Job>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlJobsPagination = {
  __typename?: 'GqlJobsPagination';
  items?: Maybe<Array<Maybe<Job>>>;
  pageInfo: PageInfo;
};

export type GqlJobsResponse = {
  __typename?: 'GqlJobsResponse';
  data?: Maybe<GqlJobsPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlJobApplicantsPagination = {
  __typename?: 'GqlJobApplicantsPagination';
  items?: Maybe<Array<Maybe<JobApplicant>>>;
  pageInfo: PageInfo;
};

export type GqlJobApplicantsResponse = {
  __typename?: 'GqlJobApplicantsResponse';
  data?: Maybe<GqlJobApplicantsPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlSubscriptionPlanResponse = {
  __typename?: 'GqlSubscriptionPlanResponse';
  data?: Maybe<SubscriptionPlan>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlSubscriptionPlansPagination = {
  __typename?: 'GqlSubscriptionPlansPagination';
  items?: Maybe<Array<Maybe<SubscriptionPlan>>>;
  pageInfo: PageInfo;
};

export type GqlSubscriptionPlansResponse = {
  __typename?: 'GqlSubscriptionPlansResponse';
  data?: Maybe<GqlSubscriptionPlansPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlTransactionResponse = {
  __typename?: 'GqlTransactionResponse';
  data?: Maybe<Transaction>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlTransactionsPagination = {
  __typename?: 'GqlTransactionsPagination';
  items?: Maybe<Array<Maybe<Transaction>>>;
  pageInfo: PageInfo;
};

export type GqlTransactionsResponse = {
  __typename?: 'GqlTransactionsResponse';
  data?: Maybe<GqlTransactionsPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlMessageResponse = {
  __typename?: 'GqlMessageResponse';
  data?: Maybe<Message>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlMessagesPagination = {
  __typename?: 'GqlMessagesPagination';
  items?: Maybe<Array<Maybe<Message>>>;
  pageInfo: PageInfo;
};

export type GqlMessagesResponse = {
  __typename?: 'GqlMessagesResponse';
  data?: Maybe<GqlMessagesPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlChatsPagination = {
  __typename?: 'GqlChatsPagination';
  items?: Maybe<Array<Maybe<Chat>>>;
  pageInfo: PageInfo;
};

export type GqlChatsResponse = {
  __typename?: 'GqlChatsResponse';
  data?: Maybe<GqlChatsPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlUserChatResponse = {
  __typename?: 'GqlUserChatResponse';
  data?: Maybe<UserChat>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlUserChatsPagination = {
  __typename?: 'GqlUserChatsPagination';
  items?: Maybe<Array<Maybe<UserChat>>>;
  pageInfo: PageInfo;
};

export type GqlUserChatsResponse = {
  __typename?: 'GqlUserChatsResponse';
  data?: Maybe<GqlUserChatsPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlReviewResponse = {
  __typename?: 'GqlReviewResponse';
  data?: Maybe<Review>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlReviewsPagination = {
  __typename?: 'GqlReviewsPagination';
  items?: Maybe<Array<Maybe<Review>>>;
  pageInfo: PageInfo;
};

export type GqlReviewsResponse = {
  __typename?: 'GqlReviewsResponse';
  data?: Maybe<GqlReviewsPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlReportResponse = {
  __typename?: 'GqlReportResponse';
  data?: Maybe<Report>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlReportsPagination = {
  __typename?: 'GqlReportsPagination';
  items?: Maybe<Array<Maybe<Report>>>;
  pageInfo: PageInfo;
};

export type GqlReportsResponse = {
  __typename?: 'GqlReportsResponse';
  data?: Maybe<GqlReportsPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlContactResponse = {
  __typename?: 'GqlContactResponse';
  data?: Maybe<Contact>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlContactsPagination = {
  __typename?: 'GqlContactsPagination';
  items?: Maybe<Array<Maybe<Contact>>>;
  pageInfo: PageInfo;
};

export type GqlContactsResponse = {
  __typename?: 'GqlContactsResponse';
  data?: Maybe<GqlContactsPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlFaqResponse = {
  __typename?: 'GqlFaqResponse';
  data?: Maybe<Faq>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlFaqsPagination = {
  __typename?: 'GqlFaqsPagination';
  items?: Maybe<Array<Maybe<Faq>>>;
  pageInfo: PageInfo;
};

export type GqlFaqsResponse = {
  __typename?: 'GqlFaqsResponse';
  data?: Maybe<GqlFaqsPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlCardResponse = {
  __typename?: 'GqlCardResponse';
  data?: Maybe<Card>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GqlCardsPagination = {
  __typename?: 'GqlCardsPagination';
  items?: Maybe<Array<Maybe<Card>>>;
  pageInfo: PageInfo;
};

export type GqlCardsResponse = {
  __typename?: 'GqlCardsResponse';
  data?: Maybe<GqlCardsPagination>;
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  findSubject: GqlSubjectResponse;
  Subjects: GqlSubjectsArrayResponse;
  me: GqlUserResponse;
  cities: GqlCitysArrayResponse;
  findUser: GqlUserResponse;
  usersBoard: GqlUsersResponse;
  viewUsersRequestsBoard: GqlUsersResponse;
  viewUserRequestBoard: GqlUserResponse;
  myNotifications: GqlNotificationsResponse;
  myNotification: GqlNotificationResponse;
  securityGroups: GqlSecurityGroupsArrayResponse;
  securityGroupPermissions: GqlStringsArrayResponse;
  securityGroup: GqlSecurityGroupResponse;
  securityGroupUsers: GqlUsersArrayResponse;
  getAdminById: GqlUserResponse;
  findCurriculum: GqlCurriculumResponse;
  Curriculums: GqlCurriculumsArrayResponse;
  Teachers: GqlUsersResponse;
  TeachersBoard: GqlUsersResponse;
  getTeacherById: GqlTeacherResponse;
  getTeacherByIdBoard: GqlTeacherResponse;
  schools: GqlSchoolsResponse;
  schoolsBoard: GqlSchoolsResponse;
  findSchoolById: GqlSchoolResponse;
  findSchoolByIdBoard: GqlSchoolResponse;
  getUsers: GqlUsersArrayResponse;
  getAdminToken: GqlStringResponse;
  getUserToken: GqlStringResponse;
  termsAndConditions: GqlStringResponse;
  appConfigurationBoard: GqlAppConfigurationResponse;
  appConfigurationsBoard: GqlAppConfigurationsArrayResponse;
  transactionsBoard: GqlTransactionsResponse;
  transactionBoard: GqlTransactionResponse;
  myTransactions: GqlTransactionsResponse;
  myTransaction: GqlTransactionResponse;
  subscriptionPlans: GqlSubscriptionPlansResponse;
  getSubscriptionPlansBoard: GqlSubscriptionPlansResponse;
  subscriptionPlan: GqlSubscriptionPlanResponse;
  documentsUpdatesBoard: GqlDocumentUpdatesResponse;
  documentUpdateBoard: GqlDocumentUpdateResponse;
  myDocumentsUpdates: GqlDocumentUpdatesResponse;
  myDocumentUpdate: GqlDocumentUpdateResponse;
  faqBoard: GqlFaqResponse;
  faqsBoard: GqlFaqsResponse;
  faqs: GqlFaqsResponse;
  job: GqlJobResponse;
  JobBoard: GqlJobResponse;
  jobs: GqlJobsResponse;
  jobsBoard: GqlJobsResponse;
  schoolJobs: GqlJobsResponse;
  myJobApplicants: GqlJobApplicantsResponse;
  myAppliedJobs: GqlJobApplicantsResponse;
  findChat: GqlUserChatResponse;
  userInbox: GqlUserChatsResponse;
  searchChatMessages: GqlMessagesResponse;
  findMessagePage: GqlNumberResponse;
  findChatMessagesBoard: GqlMessagesResponse;
  findChatsBoard: GqlChatsResponse;
  findChatByParticipants: GqlUserChatResponse;
  findChatByParticipantsBoard: GqlUserChatResponse;
  findChatMessagesByParticipants: GqlMessagesResponse;
  contactsFilter: GqlUserChatsResponse;
  startTyping: GqlBooleanResponse;
  mySavedProfiles: GqlUsersResponse;
  contactsBoard: GqlContactsResponse;
  contactBoard: GqlContactResponse;
  myCard: GqlCardResponse;
  myCards: GqlCardsResponse;
  myFollowers: GqlUsersResponse;
  myFollowedSchools: GqlSchoolsResponse;
  myReviews: GqlReviewsResponse;
  schoolReviews: GqlReviewsResponse;
  teacherReviews: GqlReviewsResponse;
  review: GqlReviewResponse;
  reviewsBoard: GqlReviewsResponse;
  reviewBoard: GqlReviewResponse;
  getChatReportsByFilter: GqlUserChatsResponse;
  getChatReportById: GqlUserChatResponse;
  getReports: GqlReportsResponse;
  getReportById: GqlReportResponse;
  numberOfJobs: GqlNumberResponse;
  numberOfTeachers: GqlNumberResponse;
  numberOfSchools: GqlNumberResponse;
  numberOfNotHiredJobs: GqlNumberResponse;
  numberOfHiredJobs: GqlNumberResponse;
};


export type QueryFindSubjectArgs = {
  id: Scalars['ID'];
};


export type QueryFindUserArgs = {
  id: Scalars['String'];
};


export type QueryUsersBoardArgs = {
  input: UsersBoardInput;
};


export type QueryViewUsersRequestsBoardArgs = {
  input: ViewUsersRequestsInput;
};


export type QueryViewUserRequestBoardArgs = {
  id: Scalars['ID'];
};


export type QueryMyNotificationsArgs = {
  input: MyNotificationsInput;
};


export type QueryMyNotificationArgs = {
  notificationId: Scalars['ID'];
};


export type QuerySecurityGroupArgs = {
  filter: SecurityGroupFilterInput;
};


export type QuerySecurityGroupUsersArgs = {
  filter: SecurityGroupFilterInput;
};


export type QueryGetAdminByIdArgs = {
  id: Scalars['String'];
};


export type QueryFindCurriculumArgs = {
  id: Scalars['ID'];
};


export type QueryTeachersArgs = {
  input: GetTeachersInput;
};


export type QueryTeachersBoardArgs = {
  input: GetTeachersBoardInput;
};


export type QueryGetTeacherByIdArgs = {
  input: GetTeacherByIdInput;
};


export type QueryGetTeacherByIdBoardArgs = {
  input: GetTeacherByIdInput;
};


export type QuerySchoolsArgs = {
  input: SchoolsFiltersAndPagination;
};


export type QuerySchoolsBoardArgs = {
  input: SchoolsFiltersAndPagination;
};


export type QueryFindSchoolByIdArgs = {
  input: FindSchoolByIdInput;
};


export type QueryFindSchoolByIdBoardArgs = {
  input: FindSchoolByIdInput;
};


export type QueryGetUserTokenArgs = {
  id: Scalars['String'];
};


export type QueryAppConfigurationBoardArgs = {
  input: AppConfigurationInput;
};


export type QueryTransactionsBoardArgs = {
  input: TransactionsBoardInput;
};


export type QueryTransactionBoardArgs = {
  transactionId: Scalars['ID'];
};


export type QueryMyTransactionsArgs = {
  input: MyTransactionsInput;
};


export type QueryMyTransactionArgs = {
  transactionId: Scalars['ID'];
};


export type QuerySubscriptionPlansArgs = {
  input: GetSubscriptionPlansInput;
};


export type QueryGetSubscriptionPlansBoardArgs = {
  input: GetSubscriptionPlansInput;
};


export type QuerySubscriptionPlanArgs = {
  subscriptionPlanId: Scalars['ID'];
};


export type QueryDocumentsUpdatesBoardArgs = {
  input: DocumentsUpdatesBoardInput;
};


export type QueryDocumentUpdateBoardArgs = {
  documentUpdateId: Scalars['ID'];
};


export type QueryMyDocumentsUpdatesArgs = {
  input: MyDocumentsUpdatesInput;
};


export type QueryMyDocumentUpdateArgs = {
  documentUpdateId: Scalars['ID'];
};


export type QueryFaqBoardArgs = {
  faqId: Scalars['String'];
};


export type QueryFaqsBoardArgs = {
  input: FaqsBoardInput;
};


export type QueryFaqsArgs = {
  input: FaqsInput;
};


export type QueryJobArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobBoardArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobsArgs = {
  input: JobsInput;
};


export type QueryJobsBoardArgs = {
  input: JobsBoardInput;
};


export type QuerySchoolJobsArgs = {
  input: SchoolJobsInput;
};


export type QueryMyJobApplicantsArgs = {
  input: MyJobApplicantsInput;
};


export type QueryMyAppliedJobsArgs = {
  input: MyAppliedJobsInput;
};


export type QueryFindChatArgs = {
  input: ChatIdInput;
};


export type QueryUserInboxArgs = {
  input: UserInboxInput;
};


export type QuerySearchChatMessagesArgs = {
  input: SearchChatMessagesInput;
};


export type QueryFindMessagePageArgs = {
  input: FindMessagePageInput;
};


export type QueryFindChatMessagesBoardArgs = {
  input: FindChatMessagesInput;
};


export type QueryFindChatsBoardArgs = {
  input: FindChatsBoardInput;
};


export type QueryFindChatByParticipantsArgs = {
  input: FindChatByParticipantInput;
};


export type QueryFindChatByParticipantsBoardArgs = {
  input: FindChatByParticipantBoardInput;
};


export type QueryFindChatMessagesByParticipantsArgs = {
  input: FindChatMessagesByParticipantsInput;
};


export type QueryContactsFilterArgs = {
  input: ContactsFiltersAndPagination;
};


export type QueryStartTypingArgs = {
  input: ChatTypingInput;
};


export type QueryMySavedProfilesArgs = {
  input: MySavedProfilesInput;
};


export type QueryContactsBoardArgs = {
  input: ContactsBoardInput;
};


export type QueryContactBoardArgs = {
  contactId: Scalars['ID'];
};


export type QueryMyCardArgs = {
  cardId: Scalars['ID'];
};


export type QueryMyCardsArgs = {
  input: MyCardsInput;
};


export type QueryMyFollowersArgs = {
  input: MyFollowersInput;
};


export type QueryMyFollowedSchoolsArgs = {
  input: MyFollowedSchoolsInput;
};


export type QueryMyReviewsArgs = {
  input: MyReviewsInput;
};


export type QuerySchoolReviewsArgs = {
  input: SchoolReviewsInput;
};


export type QueryTeacherReviewsArgs = {
  input: TeacherReviewsInput;
};


export type QueryReviewArgs = {
  reviewId: Scalars['ID'];
};


export type QueryReviewsBoardArgs = {
  input: ReviewsBoardInput;
};


export type QueryReviewBoardArgs = {
  reviewId: Scalars['ID'];
};


export type QueryGetChatReportsByFilterArgs = {
  input: ChatReportFilterInput;
};


export type QueryGetChatReportByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetReportsArgs = {
  input: ReportsFilterInput;
};


export type QueryGetReportByIdArgs = {
  id: Scalars['String'];
};

export type UsersBoardInput = {
  paginate?: Maybe<PaginatorInput>;
  searchKey?: Maybe<Scalars['String']>;
};

export type PaginatorInput = {
  page?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};

export type ViewUsersRequestsInput = {
  sortBy?: Maybe<Array<Maybe<ViewUsersRequestsOrderByOptions>>>;
  filterBy?: Maybe<ViewUsersRequestsFilterByOptions>;
  searchKey?: Maybe<Scalars['String']>;
  paginate?: Maybe<PaginatorInput>;
};

export type ViewUsersRequestsOrderByOptions = {
  createdAt: SortEnum;
};

export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ViewUsersRequestsFilterByOptions = {
  role?: Maybe<TeacherOrSchoolAdminEnum>;
  status?: Maybe<Array<Maybe<UserRequestsEnum>>>;
};

export enum TeacherOrSchoolAdminEnum {
  Teacher = 'TEACHER',
  Schooladmin = 'SCHOOLADMIN'
}

export enum UserRequestsEnum {
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type MyNotificationsInput = {
  searchKey?: Maybe<Scalars['String']>;
  paginate?: Maybe<PaginatorInput>;
};

export type SecurityGroupFilterInput = {
  groupName?: Maybe<Scalars['String']>;
  securityGrouptId?: Maybe<Scalars['String']>;
};

/** this is query works for both list and map filter if you search with map provide both lat and long  */
export type GetTeachersInput = {
  page?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  filterBy?: Maybe<TeachersFiltersByInput>;
  /** this search key for search for teachers with their first and last name only */
  searchKey?: Maybe<Scalars['String']>;
  /** this is lat of point you might provide it if you search in the map to get closer result of the point */
  lat?: Maybe<Scalars['Float']>;
  /** this is long of point you might provide it if you search in the map to get closer result of the point */
  long?: Maybe<Scalars['Float']>;
};

export type TeachersFiltersByInput = {
  subjects?: Maybe<Array<Maybe<Scalars['ID']>>>;
  grades?: Maybe<Array<Maybe<EducationalGradesEnum>>>;
  curriculums?: Maybe<Array<Maybe<Scalars['ID']>>>;
  gender?: Maybe<SearchByUserGenderEnum>;
  rating?: Maybe<Scalars['Float']>;
  minExperience?: Maybe<Scalars['Float']>;
  maxExperience?: Maybe<Scalars['Float']>;
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** if it used with lat and long it will take it as starting point if lat and long not provided it will take school admin location instead.the distance in km */
  max_distance?: Maybe<Scalars['Float']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export enum SearchByUserGenderEnum {
  Male = 'MALE',
  Female = 'FEMALE',
  All = 'All'
}

/** this is query works for both list and map filter if you search with map provide both lat and long  */
export type GetTeachersBoardInput = {
  /** this search key for search for teachers with their first and last name only */
  searchKey?: Maybe<Scalars['String']>;
  paginate?: Maybe<PaginatorInput>;
  sortBy?: Maybe<TeachersBoardSortByOptions>;
};

export type TeachersBoardSortByOptions = {
  createdAt: SortEnum;
};

export type GetTeacherByIdInput = {
  id: Scalars['ID'];
};

export type SchoolsFiltersAndPagination = {
  paginate?: Maybe<PaginatorInput>;
  schoolsFilter?: Maybe<SchoolsInput>;
  /** this is lat of point you might provide it if you search in the map to get closer result of the point */
  lat?: Maybe<Scalars['Float']>;
  /** this is long of point you might provide it if you search in the map to get closer result of the point */
  long?: Maybe<Scalars['Float']>;
  maxDistance?: Maybe<Scalars['Float']>;
  following?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<SchoolsBoardSortByOptions>;
};

export type SchoolsInput = {
  subjects?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name?: Maybe<Scalars['String']>;
  curriculums?: Maybe<Array<Maybe<Scalars['ID']>>>;
  grades?: Maybe<Array<Maybe<EducationalGradesEnum>>>;
  jobsOnly?: Maybe<Scalars['Boolean']>;
  citiesIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  rating?: Maybe<Scalars['Float']>;
};

export type SchoolsBoardSortByOptions = {
  createdAt: SortEnum;
};

export type FindSchoolByIdInput = {
  schoolId: Scalars['ID'];
};

export type AppConfigurationInput = {
  key?: Maybe<Scalars['String']>;
  appConfigurationId?: Maybe<Scalars['ID']>;
};

export type TransactionsBoardInput = {
  paginate?: Maybe<PaginatorInput>;
  sortBy?: Maybe<Array<Maybe<TransactionsBoardSortByOptions>>>;
  filterBy?: Maybe<TransactionsFilterByOptions>;
};

export type TransactionsBoardSortByOptions = {
  createdAt?: Maybe<SortEnum>;
  startsAt?: Maybe<SortEnum>;
  expiresIn?: Maybe<SortEnum>;
  amount?: Maybe<SortEnum>;
};

export type TransactionsFilterByOptions = {
  isExpired?: Maybe<Scalars['Boolean']>;
  isFuture?: Maybe<Scalars['Boolean']>;
  paymentStatus?: Maybe<PaymentStatusEnum>;
  usersIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  paymentMethod?: Maybe<Array<Maybe<PaymentMethodEnum>>>;
};

export type MyTransactionsInput = {
  paginate?: Maybe<PaginatorInput>;
  sortBy?: Maybe<Array<Maybe<MyTransactionsSortByOptions>>>;
  filterBy?: Maybe<MyTransactionsFilterByOptions>;
};

export type MyTransactionsSortByOptions = {
  createdAt?: Maybe<SortEnum>;
  startsAt?: Maybe<SortEnum>;
  expiresIn?: Maybe<SortEnum>;
};

export type MyTransactionsFilterByOptions = {
  isExpired?: Maybe<Scalars['Boolean']>;
  isFuture?: Maybe<Scalars['Boolean']>;
  paymentStatus?: Maybe<Array<Maybe<PaymentStatusEnum>>>;
};

export type GetSubscriptionPlansInput = {
  paginate?: Maybe<PaginatorInput>;
  sortBy?: Maybe<Array<Maybe<GetSubscriptionPlansSortByOptions>>>;
};

export type GetSubscriptionPlansSortByOptions = {
  createdAt: SortEnum;
};

export type DocumentsUpdatesBoardInput = {
  sortBy?: Maybe<Array<Maybe<DocumentsUpdatesBoardSortByOptions>>>;
  filterBy?: Maybe<DocumentsUpdatesBoardFilterByOptions>;
  paginate?: Maybe<PaginatorInput>;
};

export type DocumentsUpdatesBoardSortByOptions = {
  createdAt: SortEnum;
};

export type DocumentsUpdatesBoardFilterByOptions = {
  status?: Maybe<Array<DocumentStatusEnum>>;
  documentType?: Maybe<Array<DocumentTypeEnum>>;
  role?: Maybe<TeacherOrSchoolAdminEnum>;
  searchKey?: Maybe<Scalars['String']>;
};

export type MyDocumentsUpdatesInput = {
  sortBy?: Maybe<Array<Maybe<MyDocumentsUpdatesSortByOptions>>>;
  filterBy?: Maybe<MyDocumentsUpdatesFilterByOptions>;
  paginate?: Maybe<PaginatorInput>;
};

export type MyDocumentsUpdatesSortByOptions = {
  createdAt: SortEnum;
};

export type MyDocumentsUpdatesFilterByOptions = {
  status?: Maybe<Array<DocumentStatusEnum>>;
  documentType?: Maybe<Array<DocumentTypeEnum>>;
};

export type FaqsBoardInput = {
  filterBy?: Maybe<FaqsFilterByBoardOptions>;
  sortBy?: Maybe<FaqsSortByOptionsBoardOptions>;
  paginate?: Maybe<PaginatorInput>;
};

export type FaqsFilterByBoardOptions = {
  searchKey?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type FaqsSortByOptionsBoardOptions = {
  createdAt: SortEnum;
};

export type FaqsInput = {
  filterBy?: Maybe<FaqsFilterByOptions>;
  paginate?: Maybe<PaginatorInput>;
};

export type FaqsFilterByOptions = {
  searchKey: Scalars['String'];
};

export type JobsInput = {
  paginate?: Maybe<PaginatorInput>;
  jobsFilter?: Maybe<JobsFilters>;
  /** this is lat of point you might provide it if you search in the map to get closer result of the point */
  lat?: Maybe<Scalars['Float']>;
  /** this is long of point you might provide it if you search in the map to get closer result of the point */
  long?: Maybe<Scalars['Float']>;
  maxDistance?: Maybe<Scalars['Float']>;
};

export type JobsFilters = {
  subjects?: Maybe<Array<Maybe<Scalars['ID']>>>;
  grades?: Maybe<Array<Maybe<EducationalGradesEnum>>>;
  curriculums?: Maybe<Array<Maybe<Scalars['ID']>>>;
  minExperience?: Maybe<Scalars['Float']>;
  maxExperience?: Maybe<Scalars['Float']>;
  applicantsAllowedCities?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type JobsBoardInput = {
  paginate?: Maybe<PaginatorInput>;
  searchKey?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<Maybe<JobsSortByOptions>>>;
};

export type JobsSortByOptions = {
  createdAt: SortEnum;
};

export type SchoolJobsInput = {
  paginate?: Maybe<PaginatorInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  schoolId?: Maybe<Scalars['ID']>;
};

export type MyJobApplicantsInput = {
  jobId: Scalars['ID'];
  status?: Maybe<JobApplicantStatusEnum>;
  sortBy?: Maybe<Array<Maybe<MyJobApplicantsByOptions>>>;
  paginate?: Maybe<PaginatorInput>;
};

export type MyJobApplicantsByOptions = {
  createdAt: SortEnum;
};

export type MyAppliedJobsInput = {
  paginate?: Maybe<PaginatorInput>;
  sortBy?: Maybe<Array<Maybe<MyAppliedJobsSortOptions>>>;
};

export type MyAppliedJobsSortOptions = {
  createdAt: SortEnum;
};

export type ChatIdInput = {
  id: Scalars['ID'];
};

export type UserInboxInput = {
  paginate?: Maybe<PaginatorInput>;
  status?: Maybe<Array<Maybe<ChatStatusInputEnum>>>;
};

export enum ChatStatusInputEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Favorite = 'FAVORITE'
}

export type SearchChatMessagesInput = {
  content: Scalars['ID'];
  paginate?: Maybe<PaginatorInput>;
};

export type FindMessagePageInput = {
  messageId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
};

export type FindChatMessagesInput = {
  paginate?: Maybe<PaginatorInput>;
  chatId: Scalars['ID'];
};

export type FindChatsBoardInput = {
  paginate?: Maybe<PaginatorInput>;
  searchKey?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<Maybe<ChatsBoardSortByOptions>>>;
};

export type ChatsBoardSortByOptions = {
  createdAt: SortEnum;
};

export type FindChatByParticipantInput = {
  recipientId: Scalars['ID'];
};

export type FindChatByParticipantBoardInput = {
  userId: Scalars['ID'];
  recipientId: Scalars['ID'];
};

export type FindChatMessagesByParticipantsInput = {
  paginate?: Maybe<PaginatorInput>;
  recipientId: Scalars['ID'];
};

export type ContactsFiltersAndPagination = {
  paginate?: Maybe<PaginatorInput>;
  schoolsFilter?: Maybe<SchoolsFilter>;
  teachersFilter?: Maybe<TeachersFilter>;
  /** this is lat of point you might provide it if you search in the map to get closer result of the point */
  lat?: Maybe<Scalars['Float']>;
  /** this is long of point you might provide it if you search in the map to get closer result of the point */
  long?: Maybe<Scalars['Float']>;
  maxDistance?: Maybe<Scalars['Float']>;
};

export type SchoolsFilter = {
  subjects?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name?: Maybe<Scalars['String']>;
  curriculums?: Maybe<Array<Maybe<Scalars['ID']>>>;
  grades?: Maybe<Array<Maybe<EducationalGradesEnum>>>;
  jobsOnly?: Maybe<Scalars['Boolean']>;
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
  following?: Maybe<Scalars['Boolean']>;
};

export type TeachersFilter = {
  subjects?: Maybe<Array<Maybe<Scalars['ID']>>>;
  grades?: Maybe<Array<Maybe<EducationalGradesEnum>>>;
  curriculums?: Maybe<Array<Maybe<Scalars['ID']>>>;
  gender?: Maybe<SearchByUserGenderEnum>;
  rating?: Maybe<Scalars['Float']>;
  minExperience?: Maybe<Scalars['Float']>;
  maxExperience?: Maybe<Scalars['Float']>;
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type ChatTypingInput = {
  recipientId: Scalars['String'];
  typingStatus: TypingStatusEnum;
};

export enum TypingStatusEnum {
  Started = 'STARTED',
  Stopped = 'STOPPED'
}

export type MySavedProfilesInput = {
  sortBy?: Maybe<Array<Maybe<MySavedProfilesSortByOptions>>>;
  paginate?: Maybe<PaginatorInput>;
};

export type MySavedProfilesSortByOptions = {
  createdAt: SortEnum;
};

export type ContactsBoardInput = {
  paginate?: Maybe<PaginatorInput>;
  filter?: Maybe<ContactFilterBoardInput>;
};

export type ContactFilterBoardInput = {
  searchKey?: Maybe<Scalars['String']>;
  isResolved?: Maybe<Scalars['Boolean']>;
};

export type MyCardsInput = {
  paginate?: Maybe<PaginatorInput>;
  sortBy?: Maybe<Array<Maybe<MyCardsSortByOptions>>>;
};

export type MyCardsSortByOptions = {
  createdAt?: Maybe<SortEnum>;
};

export type MyFollowersInput = {
  sortBy?: Maybe<Array<Maybe<MyFollowersSortByOptions>>>;
  paginate?: Maybe<PaginatorInput>;
};

export type MyFollowersSortByOptions = {
  createdAt: SortEnum;
};

export type MyFollowedSchoolsInput = {
  sortBy?: Maybe<Array<Maybe<MyFollowedSchoolsSortByOptions>>>;
  paginate?: Maybe<PaginatorInput>;
};

export type MyFollowedSchoolsSortByOptions = {
  createdAt: SortEnum;
};

export type MyReviewsInput = {
  sortBy?: Maybe<Array<Maybe<ReviewsSortByOptions>>>;
  paginate?: Maybe<PaginatorInput>;
};

export type ReviewsSortByOptions = {
  createdAt?: Maybe<SortEnum>;
  rate?: Maybe<SortEnum>;
};

export type SchoolReviewsInput = {
  sortBy?: Maybe<Array<Maybe<ReviewsSortByOptions>>>;
  paginate?: Maybe<PaginatorInput>;
  schoolId: Scalars['ID'];
};

export type TeacherReviewsInput = {
  sortBy?: Maybe<Array<Maybe<ReviewsSortByOptions>>>;
  paginate?: Maybe<PaginatorInput>;
  teacherId: Scalars['ID'];
};

export type ReviewsBoardInput = {
  filterBy?: Maybe<ReviewsBoardFilterByOptions>;
  sortBy?: Maybe<Array<Maybe<ReviewsBoardSortByOptions>>>;
  paginate?: Maybe<PaginatorInput>;
};

export type ReviewsBoardFilterByOptions = {
  schoolId?: Maybe<Scalars['String']>;
  teacherId?: Maybe<Scalars['String']>;
  reviewerId?: Maybe<Scalars['String']>;
  reviewedRule?: Maybe<TeacherOrSchoolAdminEnum>;
  reviewerRule?: Maybe<TeacherOrSchoolAdminEnum>;
  searchKey?: Maybe<Scalars['String']>;
};

export type ReviewsBoardSortByOptions = {
  createdAt?: Maybe<SortEnum>;
  rate?: Maybe<SortEnum>;
};

export type ChatReportFilterInput = {
  paginate?: Maybe<PaginatorInput>;
  userId?: Maybe<Scalars['String']>;
};

export type ReportsFilterInput = {
  paginate?: Maybe<PaginatorInput>;
  reportType?: Maybe<ReportTypeEnum>;
};

export enum ReportTypeEnum {
  Job = 'JOB',
  School = 'SCHOOL',
  Teacher = 'TEACHER'
}

export type Mutation = {
  __typename?: 'Mutation';
  createSubject: GqlSubjectResponse;
  updateSubject: GqlSubjectResponse;
  deleteSubject: GqlBasicResponse;
  uploadFile: GqlFileResponse;
  login: GqlUserResponse;
  loginBoard: GqlUserResponse;
  sendPhoneVerificationCode: GqlUserResponse;
  verifyPhoneVerificationCode: GqlUserResponse;
  sendResetPasswordCode: GqlBooleanResponse;
  resetPasswordByPhone: GqlUserResponse;
  verifyResetPasswordVerificationCode: GqlBooleanResponse;
  logout: GqlBooleanResponse;
  updateFcmToken: GqlBooleanResponse;
  setLastActiveDetails: GqlUserResponse;
  blockUser: GqlBooleanResponse;
  changePassword: GqlUserResponse;
  updateUserStatus: GqlUserResponse;
  seedAdmin: GqlBooleanResponse;
  createCity: GqlCityResponse;
  seedCities: GqlCitysArrayResponse;
  setNotificationsInSeenStatus: GqlBooleanResponse;
  deleteNotification: GqlBasicResponse;
  manageMyNotificationsAsSchoolAdmin: GqlUserResponse;
  manageMyNotificationsAsTeacher: GqlUserResponse;
  sendNotificationBoard: GqlBooleanResponse;
  createSecurityGroup: GqlSecurityGroupResponse;
  updateSecurityGroup: GqlSecurityGroupResponse;
  deleteSecurityGroup: GqlSecurityGroupResponse;
  createAdmin: GqlUserResponse;
  updateAdmin: GqlUserResponse;
  deleteAdmin: GqlUserResponse;
  createCurriculum: GqlCurriculumResponse;
  updateCurriculum: GqlCurriculumResponse;
  deleteCurriculum: GqlBooleanResponse;
  registerAsTeacher: GqlUserResponse;
  completeTeacherProfile: GqlUserResponse;
  updateTeacherProfile: GqlUserResponse;
  teacherAvailability: GqlBooleanResponse;
  registerAsSchoolAdmin: GqlUserResponse;
  completeRegistrationAsSchoolAdmin: GqlUserResponse;
  updateSchoolProfile: GqlUserResponse;
  createAdminToken: GqlStringResponse;
  createFakeUsers: GqlUsersArrayResponse;
  seedCurriculums: GqlCurriculumsArrayResponse;
  seedSubjects: GqlSubjectsArrayResponse;
  createAppConfigurationBoard: GqlAppConfigurationResponse;
  updateAppConfigurationBoard: GqlAppConfigurationResponse;
  activateSubscriptionPlan: GqlTransactionResponse;
  cancelSubscriptionPayment: GqlBasicResponse;
  createSubscriptionPlanBoard: GqlSubscriptionPlanResponse;
  updateSubscriptionPlanBoard: GqlSubscriptionPlanResponse;
  deleteSubscriptionPlanBoard: GqlBasicResponse;
  createCustomerEphemeralKey: GqlStringResponse;
  updateDocumentsAsTeacher: GqlUserResponse;
  updateDocumentsAsSchoolAdmin: GqlUserResponse;
  updateUserDocumentsBoard: GqlDocumentUpdateResponse;
  createFaqBoard: GqlFaqResponse;
  updateFaqBoard: GqlFaqResponse;
  deleteFaqBoard: GqlBasicResponse;
  postJob: GqlJobResponse;
  updateJob: GqlJobResponse;
  hire: GqlBasicResponse;
  complete: GqlBasicResponse;
  manageApplication: GqlBooleanResponse;
  sendMessage: GqlMessageResponse;
  reportChat: GqlUserChatResponse;
  changeChatStatus: GqlUserChatResponse;
  manageSaveProfile: GqlBooleanResponse;
  createContact: GqlContactResponse;
  updateContactBoard: GqlContactResponse;
  manageFollow: GqlBooleanResponse;
  creatSchoolReview: GqlReviewResponse;
  creatTeacherReview: GqlReviewResponse;
  updateReview: GqlReviewResponse;
  deleteReview: GqlBasicResponse;
  deleteReviewBoard: GqlBasicResponse;
  deleteReport: GqlBooleanResponse;
  deleteChatReport: GqlBooleanResponse;
  createReport: GqlReportResponse;
  activateInAppAppleSubscriptionPlan: GqlTransactionResponse;
};


export type MutationCreateSubjectArgs = {
  input: SubjectInput;
};


export type MutationUpdateSubjectArgs = {
  input: UpdateSubjectInput;
};


export type MutationDeleteSubjectArgs = {
  id: Scalars['ID'];
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  model: FileModelEnum;
};


export type MutationLoginArgs = {
  loginInput: PhoneAndPasswordLoginInput;
};


export type MutationLoginBoardArgs = {
  input: LoginBoardInput;
};


export type MutationSendPhoneVerificationCodeArgs = {
  updatedPhone?: Maybe<Scalars['String']>;
};


export type MutationVerifyPhoneVerificationCodeArgs = {
  code: Scalars['String'];
};


export type MutationSendResetPasswordCodeArgs = {
  phone: Scalars['String'];
};


export type MutationResetPasswordByPhoneArgs = {
  input: ResetPasswordByPhoneInput;
};


export type MutationVerifyResetPasswordVerificationCodeArgs = {
  input: VerifyResetPasswordVerificationCode;
};


export type MutationLogoutArgs = {
  device: DeviceEnum;
};


export type MutationUpdateFcmTokenArgs = {
  fcmToken: Scalars['String'];
  device: DeviceEnum;
};


export type MutationSetLastActiveDetailsArgs = {
  platformDetails: Scalars['JSON'];
  device: DeviceEnum;
};


export type MutationBlockUserArgs = {
  input: BlockUserInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationUpdateUserStatusArgs = {
  input: UpdateUserStatusInput;
};


export type MutationCreateCityArgs = {
  input: CreateCityInput;
};


export type MutationSetNotificationsInSeenStatusArgs = {
  input: SetNotificationsInSeenStatusInput;
};


export type MutationDeleteNotificationArgs = {
  notificationId: Scalars['ID'];
};


export type MutationManageMyNotificationsAsSchoolAdminArgs = {
  input: ManageMyNotificationsAsSchoolAdminInput;
};


export type MutationManageMyNotificationsAsTeacherArgs = {
  input: ManageMyNotificationsAsTeacherInput;
};


export type MutationSendNotificationBoardArgs = {
  input: SendNotificationBoardInput;
};


export type MutationCreateSecurityGroupArgs = {
  input: CreatSecurityGroupInput;
};


export type MutationUpdateSecurityGroupArgs = {
  input: UpdateSecurityGroupInput;
};


export type MutationDeleteSecurityGroupArgs = {
  securityGrouptId: Scalars['String'];
};


export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};


export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
};


export type MutationDeleteAdminArgs = {
  input: DeleteAdminInput;
};


export type MutationCreateCurriculumArgs = {
  input: CurriculumInput;
};


export type MutationUpdateCurriculumArgs = {
  input: UpdateCurriculumInput;
};


export type MutationDeleteCurriculumArgs = {
  id: Scalars['ID'];
};


export type MutationRegisterAsTeacherArgs = {
  input: RegisterAsTeacherInput;
};


export type MutationCompleteTeacherProfileArgs = {
  input: CompleteTeacherRegistrationInput;
};


export type MutationUpdateTeacherProfileArgs = {
  input: UpdateTeacherProfileInput;
};


export type MutationRegisterAsSchoolAdminArgs = {
  input: RegisterAsSchoolInput;
};


export type MutationCompleteRegistrationAsSchoolAdminArgs = {
  input: CompleteRegistrationAsSchool;
};


export type MutationUpdateSchoolProfileArgs = {
  input: UpdateSchoolProfileInput;
};


export type MutationCreateAppConfigurationBoardArgs = {
  input: CreateAppConfigurationInput;
};


export type MutationUpdateAppConfigurationBoardArgs = {
  input: UpdateAppConfigurationInput;
};


export type MutationActivateSubscriptionPlanArgs = {
  input: ActivateSubscriptionPlanInput;
};


export type MutationCancelSubscriptionPaymentArgs = {
  transactionId: Scalars['ID'];
};


export type MutationCreateSubscriptionPlanBoardArgs = {
  input: CreateSubscriptionPlanBoardInput;
};


export type MutationUpdateSubscriptionPlanBoardArgs = {
  input: UpdateSubscriptionPlanBoardInput;
};


export type MutationDeleteSubscriptionPlanBoardArgs = {
  subscriptionPlanId: Scalars['ID'];
};


export type MutationUpdateDocumentsAsTeacherArgs = {
  input: UpdateDocumentsAsTeacherInput;
};


export type MutationUpdateDocumentsAsSchoolAdminArgs = {
  input: UpdateDocumentsAsSchoolAdminInput;
};


export type MutationUpdateUserDocumentsBoardArgs = {
  input: UpdateUserDocumentsBoardInput;
};


export type MutationCreateFaqBoardArgs = {
  input: CreateFaqInput;
};


export type MutationUpdateFaqBoardArgs = {
  input: UpdateFaqInput;
};


export type MutationDeleteFaqBoardArgs = {
  faqId: Scalars['String'];
};


export type MutationPostJobArgs = {
  input: PostJobInput;
};


export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};


export type MutationHireArgs = {
  input: JobHiringInput;
};


export type MutationCompleteArgs = {
  input: CompleteJobInput;
};


export type MutationManageApplicationArgs = {
  input: ApplyToJobInput;
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};


export type MutationReportChatArgs = {
  input: ReportChatInput;
};


export type MutationChangeChatStatusArgs = {
  input: ChangeChatStatus;
};


export type MutationManageSaveProfileArgs = {
  userId: Scalars['ID'];
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationUpdateContactBoardArgs = {
  input: UpdateContactBoardInput;
};


export type MutationManageFollowArgs = {
  schoolId: Scalars['ID'];
};


export type MutationCreatSchoolReviewArgs = {
  input: CreateSchoolReviewInput;
};


export type MutationCreatTeacherReviewArgs = {
  input: CreateTeacherReviewInput;
};


export type MutationUpdateReviewArgs = {
  input: UpdateReviewInput;
};


export type MutationDeleteReviewArgs = {
  reviewId: Scalars['ID'];
};


export type MutationDeleteReviewBoardArgs = {
  reviewId: Scalars['ID'];
};


export type MutationDeleteReportArgs = {
  input: DeleteReportInputs;
};


export type MutationDeleteChatReportArgs = {
  input: DeleteReportInputs;
};


export type MutationCreateReportArgs = {
  input: ReportInput;
};


export type MutationActivateInAppAppleSubscriptionPlanArgs = {
  input: ActivateAppleSubscriptionPlan;
};

export type SubjectInput = {
  enName: Scalars['String'];
  arName: Scalars['String'];
  arDescription?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
};

export type UpdateSubjectInput = {
  id: Scalars['ID'];
  enName?: Maybe<Scalars['String']>;
  arName?: Maybe<Scalars['String']>;
  arDescription?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
};


export enum FileModelEnum {
  Teacher = 'TEACHER',
  School = 'SCHOOL',
  User = 'USER',
  Chat = 'CHAT',
  Document = 'DOCUMENT',
  Notification = 'NOTIFICATION'
}

export type PhoneAndPasswordLoginInput = {
  phone: Scalars['String'];
  password: Scalars['String'];
  device: DeviceEnum;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  platformDetails?: Maybe<Scalars['JSON']>;
};

export type LoginBoardInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ResetPasswordByPhoneInput = {
  phone: Scalars['String'];
  code: Scalars['String'];
  newPassword: Scalars['String'];
};

export type VerifyResetPasswordVerificationCode = {
  phone: Scalars['String'];
  code: Scalars['String'];
};

export type BlockUserInput = {
  userId: Scalars['ID'];
};

export type ChangePasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type UpdateUserStatusInput = {
  id: Scalars['String'];
  status: StatusEnum;
  rejectionReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateCityInput = {
  name_ar: Scalars['String'];
  name_en: Scalars['String'];
};

export type SetNotificationsInSeenStatusInput = {
  notificationIds: Array<Scalars['String']>;
};

export type ManageMyNotificationsAsSchoolAdminInput = {
  NEW_CONTACT_MESSAGE?: Maybe<Scalars['Boolean']>;
  NEW_JOP_APPLICANT?: Maybe<Scalars['Boolean']>;
  NEW_FOLLOWER?: Maybe<Scalars['Boolean']>;
};

export type ManageMyNotificationsAsTeacherInput = {
  NEW_CONTACT_MESSAGE?: Maybe<Scalars['Boolean']>;
  NEW_JOB_POSTED?: Maybe<Scalars['Boolean']>;
};

export type SendNotificationBoardInput = {
  pushDestination: PushDestinationEnum;
  payloadData: NotificationPayloadInput;
  usersIds?: Maybe<Array<Scalars['String']>>;
};

export enum PushDestinationEnum {
  All = 'ALL',
  Superadmin = 'SUPERADMIN',
  Teacher = 'TEACHER',
  Schooladmin = 'SCHOOLADMIN',
  SpecificUsers = 'SPECIFIC_USERS'
}

export type NotificationPayloadInput = {
  enTitle?: Maybe<Scalars['String']>;
  arTitle?: Maybe<Scalars['String']>;
  enBody: Scalars['String'];
  arBody: Scalars['String'];
  thumbnail?: Maybe<Scalars['ID']>;
};

export type CreatSecurityGroupInput = {
  groupName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  permissions: Array<Scalars['String']>;
};

export type UpdateSecurityGroupInput = {
  groupName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Scalars['String']>>;
  securityGrouptId: Scalars['String'];
};

export type CreateAdminInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  securityGroupId: Scalars['String'];
};

export type UpdateAdminInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  securityGroupId?: Maybe<Scalars['String']>;
};

export type DeleteAdminInput = {
  id: Scalars['ID'];
};

export type CurriculumInput = {
  enName: Scalars['String'];
  arName: Scalars['String'];
  arDescription?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
};

export type UpdateCurriculumInput = {
  id: Scalars['String'];
  enName?: Maybe<Scalars['String']>;
  arName?: Maybe<Scalars['String']>;
  arDescription?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
};

export type RegisterAsTeacherInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  unverifiedPhone: Scalars['String'];
  password: Scalars['String'];
  gender: GenderEnum;
  cityId: Scalars['String'];
  favLang: LangEnum;
  fcmToken?: Maybe<Scalars['String']>;
  device: DeviceEnum;
  platformDetails?: Maybe<Scalars['JSON']>;
};

export type CompleteTeacherRegistrationInput = {
  grades: Array<EducationalGradesEnum>;
  subjects: Array<Scalars['String']>;
  curriculums: Array<Scalars['String']>;
  experience: Scalars['Float'];
  cv: Scalars['String'];
  idDocument: Scalars['String'];
  certificates: Array<Scalars['ID']>;
  arAddress?: Maybe<Scalars['String']>;
  enAddress?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  /** this is lat of point you might provide it if you search in the map to get closer result of the point */
  lat: Scalars['Float'];
  /** this is long of point you might provide it if you search in the map to get closer result of the point */
  long: Scalars['Float'];
};

export type UpdateTeacherProfileInput = {
  user?: Maybe<UserUpdatesInput>;
  teacher?: Maybe<TeacherUpdatesInput>;
};

export type UserUpdatesInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderEnum>;
  cityId?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  favLang?: Maybe<LangEnum>;
  birthDate?: Maybe<Scalars['Timestamp']>;
  long?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  arAddress?: Maybe<Scalars['String']>;
  enAddress?: Maybe<Scalars['String']>;
};

export type TeacherUpdatesInput = {
  grades?: Maybe<Array<Maybe<EducationalGradesEnum>>>;
  subjects?: Maybe<Array<Maybe<Scalars['ID']>>>;
  curriculums?: Maybe<Array<Maybe<Scalars['ID']>>>;
  experience?: Maybe<Scalars['Float']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

/** this is first step in registration as school admin it must query this resolver first to give him jwt token and send back otp with phone message */
export type RegisterAsSchoolInput = {
  school: SchoolRegistrationDetailsInput;
  admin: SchoolAdminRegistrationDetailsInput;
};

/** school details data for registration as school admin */
export type SchoolRegistrationDetailsInput = {
  name: Scalars['String'];
  schoolType: SchoolTypeEnum;
  email: Scalars['String'];
  phone: Scalars['String'];
  /** landline number must be sa number ex: +966112459099 */
  landlineNumber: Scalars['String'];
  cityId: Scalars['String'];
};

/** school admin details data for registration as school admin */
export type SchoolAdminRegistrationDetailsInput = {
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phone: Scalars['String'];
  device: DeviceEnum;
  fcmToken?: Maybe<Scalars['String']>;
  platformDetails?: Maybe<Scalars['JSON']>;
  cityId?: Maybe<Scalars['String']>;
};

export type CompleteRegistrationAsSchool = {
  grades: Array<EducationalGradesEnum>;
  curriculums: Array<Scalars['ID']>;
  gender: SchoolGenderEnum;
  certificates: Array<Scalars['ID']>;
  long: Scalars['Float'];
  lat: Scalars['Float'];
  arAddress?: Maybe<Scalars['String']>;
  enAddress?: Maybe<Scalars['String']>;
};

export type UpdateSchoolProfileInput = {
  admin?: Maybe<UserUpdatesInput>;
  school?: Maybe<SchoolUpdatesInput>;
};

export type SchoolUpdatesInput = {
  name?: Maybe<Scalars['String']>;
  schoolInfo?: Maybe<Scalars['String']>;
  schoolType?: Maybe<Scalars['String']>;
  grades?: Maybe<Array<Maybe<EducationalGradesEnum>>>;
  gender?: Maybe<SchoolGenderEnum>;
  curriculums?: Maybe<Array<Maybe<Scalars['ID']>>>;
  phone?: Maybe<Scalars['String']>;
  /** landline number must be sa number ex: +966112459099 */
  landlineNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  arAddress?: Maybe<Scalars['String']>;
  enAddress?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
};

export type CreateAppConfigurationInput = {
  key: Scalars['String'];
  value: Scalars['String'];
  displayAs: Scalars['String'];
};

export type UpdateAppConfigurationInput = {
  appConfigurationId: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  displayAs?: Maybe<Scalars['String']>;
};

export type ActivateSubscriptionPlanInput = {
  subscriptionPlanId: Scalars['ID'];
  cardId?: Maybe<Scalars['String']>;
};

export type CreateSubscriptionPlanBoardInput = {
  arName: Scalars['String'];
  arDescription?: Maybe<Array<Maybe<Scalars['String']>>>;
  enName: Scalars['String'];
  enDescription?: Maybe<Array<Maybe<Scalars['String']>>>;
  price: Scalars['Float'];
  numberOfDays: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  inAppPurchaseId: Scalars['String'];
};

export type UpdateSubscriptionPlanBoardInput = {
  subscriptionPlanId: Scalars['ID'];
  arName?: Maybe<Scalars['String']>;
  arDescription?: Maybe<Array<Maybe<Scalars['String']>>>;
  enName?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Array<Maybe<Scalars['String']>>>;
  price?: Maybe<Scalars['Float']>;
  numberOfDays?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type UpdateDocumentsAsTeacherInput = {
  idDocument?: Maybe<Scalars['String']>;
  cv?: Maybe<Scalars['ID']>;
  certificates?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** required only if user in accepted status and updated his documents */
  updateReason?: Maybe<Scalars['String']>;
};

export type UpdateDocumentsAsSchoolAdminInput = {
  certificates: Array<Scalars['String']>;
  /** required only if user in accepted status and updated his documents */
  updateReason?: Maybe<Scalars['String']>;
};

export type UpdateUserDocumentsBoardInput = {
  documentUpdateId: Scalars['ID'];
  status: DocumentStatusInputType;
  rejectionReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum DocumentStatusInputType {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

export type CreateFaqInput = {
  enQuestion: Scalars['String'];
  arQuestion: Scalars['String'];
  enAnswer: Scalars['String'];
  arAnswer: Scalars['String'];
};

export type UpdateFaqInput = {
  faqId: Scalars['ID'];
  enQuestion?: Maybe<Scalars['String']>;
  arQuestion?: Maybe<Scalars['String']>;
  enAnswer?: Maybe<Scalars['String']>;
  arAnswer?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type PostJobInput = {
  applicantsAllowedCities: Array<Scalars['String']>;
  subject: Scalars['ID'];
  grade: EducationalGradesEnum;
  curriculum: Scalars['ID'];
  gender: JobGenderEnum;
  minExperience: Scalars['Float'];
  maxExperience: Scalars['Float'];
  description: Scalars['String'];
  requirements: Array<Scalars['String']>;
  startDate?: Maybe<Scalars['Timestamp']>;
  endDate?: Maybe<Scalars['Timestamp']>;
  salary?: Maybe<Scalars['Float']>;
};

export type UpdateJobInput = {
  jobId: Scalars['ID'];
  jobUpdate?: Maybe<JobUpdateInput>;
};

export type JobUpdateInput = {
  description?: Maybe<Scalars['String']>;
  requirements?: Maybe<Array<Maybe<Scalars['ID']>>>;
  startDate?: Maybe<Scalars['Timestamp']>;
  endDate?: Maybe<Scalars['Timestamp']>;
  /** remove both start and end date.(hint)takes precedence over removeStartDate startDate and endDate */
  removeDate?: Maybe<Scalars['Boolean']>;
  /** remove end date only.(hint)takes precedence over endDate only */
  removeEndDate?: Maybe<Scalars['Boolean']>;
  /** if you want to remove it put 0 */
  salary?: Maybe<Scalars['Float']>;
};

export type JobHiringInput = {
  jobId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CompleteJobInput = {
  jobId: Scalars['ID'];
  reason: JobCompleteReasonEnumInput;
  /** will be ignored if provided with reason other that OTHER complete reason */
  other?: Maybe<Scalars['String']>;
};

export enum JobCompleteReasonEnumInput {
  HiredThroughApp = 'HIRED_THROUGH_APP',
  NoTeacher = 'NO_TEACHER',
  QualifiedOutsideApp = 'QUALIFIED_OUTSIDE_APP',
  TeacherNotNeeded = 'TEACHER_NOT_NEEDED',
  Other = 'OTHER'
}

export type ApplyToJobInput = {
  jobId: Scalars['ID'];
};

export type SendMessageInput = {
  receiverId: Scalars['ID'];
  attachments?: Maybe<Array<Maybe<Scalars['ID']>>>;
  content?: Maybe<Scalars['String']>;
};

export type ReportChatInput = {
  report: Scalars['String'];
  chatId: Scalars['ID'];
};

export type ChangeChatStatus = {
  id: Scalars['ID'];
  chatStatus: ChatStatusEnum;
};

export type CreateContactInput = {
  reason: Scalars['String'];
  message: Scalars['String'];
};

export type UpdateContactBoardInput = {
  contactId: Scalars['ID'];
  isResolved: Scalars['Boolean'];
};

export type CreateSchoolReviewInput = {
  schoolId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  rate: Scalars['Float'];
};

export type CreateTeacherReviewInput = {
  teacherId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  rate: Scalars['Float'];
};

export type UpdateReviewInput = {
  reviewId: Scalars['ID'];
  rate?: Maybe<Scalars['Float']>;
  content?: Maybe<Scalars['String']>;
};

export type DeleteReportInputs = {
  id: Scalars['ID'];
};

export type ReportInput = {
  jobId?: Maybe<Scalars['ID']>;
  reportedId?: Maybe<Scalars['ID']>;
  content: Scalars['String'];
};

export type ActivateAppleSubscriptionPlan = {
  receiptData: Scalars['String'];
  subscriptionPlanId: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  typingStatusChanged: GqlBooleanResponse;
  newMessageReceived: GqlMessageResponse;
};


export type SubscriptionTypingStatusChangedArgs = {
  input: StartTypingSubscriptionInput;
};


export type SubscriptionNewMessageReceivedArgs = {
  input: NewMessageInput;
};

export type StartTypingSubscriptionInput = {
  recipientId: Scalars['String'];
};

export type NewMessageInput = {
  senderId: Scalars['String'];
};

export type GetAllFaqQueryVariables = Exact<{
  input: FaqsBoardInput;
}>;


export type GetAllFaqQuery = (
  { __typename?: 'Query' }
  & { faqsBoard: (
    { __typename?: 'GqlFaqsResponse' }
    & Pick<GqlFaqsResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlFaqsPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Faq' }
        & Pick<Faq, 'id' | 'enQuestion' | 'arQuestion' | 'enAnswer' | 'arAnswer' | 'isActive'>
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type GetSingleFaqQueryVariables = Exact<{
  faqId: Scalars['String'];
}>;


export type GetSingleFaqQuery = (
  { __typename?: 'Query' }
  & { faqBoard: (
    { __typename?: 'GqlFaqResponse' }
    & Pick<GqlFaqResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'Faq' }
      & Pick<Faq, 'id' | 'enQuestion' | 'arQuestion' | 'enAnswer' | 'arAnswer' | 'isActive'>
    )> }
  ) }
);

export type CreateFaqMutationVariables = Exact<{
  input: CreateFaqInput;
}>;


export type CreateFaqMutation = (
  { __typename?: 'Mutation' }
  & { createFaqBoard: (
    { __typename?: 'GqlFaqResponse' }
    & Pick<GqlFaqResponse, 'code' | 'success' | 'message'>
  ) }
);

export type UpdateFaqMutationVariables = Exact<{
  input: UpdateFaqInput;
}>;


export type UpdateFaqMutation = (
  { __typename?: 'Mutation' }
  & { updateFaqBoard: (
    { __typename?: 'GqlFaqResponse' }
    & Pick<GqlFaqResponse, 'code' | 'success' | 'message'>
  ) }
);

export type DeleteFaqMutationVariables = Exact<{
  faqId: Scalars['String'];
}>;


export type DeleteFaqMutation = (
  { __typename?: 'Mutation' }
  & { deleteFaqBoard: (
    { __typename?: 'GqlBasicResponse' }
    & Pick<GqlBasicResponse, 'code' | 'success' | 'message'>
  ) }
);

export type GetAllTeachersQueryVariables = Exact<{
  input: GetTeachersBoardInput;
}>;


export type GetAllTeachersQuery = (
  { __typename?: 'Query' }
  & { TeachersBoard: (
    { __typename?: 'GqlUsersResponse' }
    & Pick<GqlUsersResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlUsersPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & UserFragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type GetAllSchoolsQueryVariables = Exact<{
  input: SchoolsFiltersAndPagination;
}>;


export type GetAllSchoolsQuery = (
  { __typename?: 'Query' }
  & { schoolsBoard: (
    { __typename?: 'GqlSchoolsResponse' }
    & Pick<GqlSchoolsResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlSchoolsPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'School' }
        & SchoolFragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type GetTeacherByIdQueryVariables = Exact<{
  input: GetTeacherByIdInput;
}>;


export type GetTeacherByIdQuery = (
  { __typename?: 'Query' }
  & { getTeacherByIdBoard: (
    { __typename?: 'GqlTeacherResponse' }
    & Pick<GqlTeacherResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'Teacher' }
      & TeacherFragment
    )> }
  ) }
);

export type GetSchoolByIdQueryVariables = Exact<{
  input: FindSchoolByIdInput;
}>;


export type GetSchoolByIdQuery = (
  { __typename?: 'Query' }
  & { findSchoolByIdBoard: (
    { __typename?: 'GqlSchoolResponse' }
    & Pick<GqlSchoolResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'School' }
      & SchoolFragment
    )> }
  ) }
);

export type FindUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FindUserQuery = (
  { __typename?: 'Query' }
  & { findUser: (
    { __typename?: 'GqlUserResponse' }
    & Pick<GqlUserResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'User' }
      & UserFragment
    )> }
  ) }
);

export type BlockUserMutationVariables = Exact<{
  input: BlockUserInput;
}>;


export type BlockUserMutation = (
  { __typename?: 'Mutation' }
  & { blockUser: (
    { __typename?: 'GqlBooleanResponse' }
    & Pick<GqlBooleanResponse, 'code' | 'success' | 'message'>
  ) }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'bio' | 'lastName' | 'slug' | 'rejectionReasons' | 'email' | 'status' | 'isEmailVerified' | 'phone' | 'unverifiedPhone' | 'gender' | 'birthDate' | 'role' | 'arAddress' | 'enAddress' | 'profilePicture' | 'isBlocked' | 'favLang' | 'token' | 'createdAt' | 'updatedAt' | 'stripeCustomerId' | 'isComplete' | 'awayFromCurrentUserBy' | 'subscriptionStatus' | 'saved'>
  & { location?: Maybe<(
    { __typename?: 'LocationType' }
    & LocationTypeFragment
  )>, securityGroup?: Maybe<(
    { __typename?: 'SecurityGroup' }
    & SecurityGroupFragment
  )>, notificationManager: (
    { __typename?: 'NotificationManager' }
    & Pick<NotificationManager, 'NEW_CONTACT_MESSAGE' | 'NEW_FOLLOWER' | 'NEW_JOB_POSTED' | 'NEW_JOP_APPLICANT'>
  ), lastLoginDetails?: Maybe<(
    { __typename?: 'LastLoginDetails' }
    & LastLoginDetailsFragment
  )>, school?: Maybe<(
    { __typename?: 'School' }
    & SchoolFragment
  )>, teacher?: Maybe<(
    { __typename?: 'Teacher' }
    & Pick<Teacher, 'teacherId' | 'experience' | 'grades' | 'cv' | 'idDocument' | 'certificates' | 'isAvailable' | 'avgRate' | 'noOfReviews' | 'updatedAt' | 'createdAt' | 'reviewed'>
    & { curriculums: Array<(
      { __typename?: 'Curriculum' }
      & Pick<Curriculum, 'id' | 'enName' | 'arName'>
    )>, subjects: Array<(
      { __typename?: 'Subject' }
      & Pick<Subject, 'id' | 'enName'>
    )> }
  )>, city?: Maybe<(
    { __typename?: 'City' }
    & Pick<City, 'id' | 'name_ar' | 'name_en'>
  )> }
);

export type LocationTypeFragment = (
  { __typename?: 'LocationType' }
  & Pick<LocationType, 'type' | 'coordinates'>
);

export type SecurityGroupFragment = (
  { __typename?: 'SecurityGroup' }
  & Pick<SecurityGroup, 'id' | 'groupName' | 'description' | 'permissions'>
);

export type LastLoginDetailsFragment = (
  { __typename?: 'LastLoginDetails' }
  & Pick<LastLoginDetails, 'lastLoginAt' | 'lastLoginDevice' | 'platformDetails'>
);

export type CardFragment = (
  { __typename?: 'Card' }
  & Pick<Card, 'id' | 'expMonth' | 'expYear' | 'last4' | 'brand' | 'createdAt' | 'updatedAt'>
);

export type GetAllAppConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAppConfigurationsQuery = (
  { __typename?: 'Query' }
  & { appConfigurationsBoard: (
    { __typename?: 'GqlAppConfigurationsArrayResponse' }
    & Pick<GqlAppConfigurationsArrayResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'AppConfiguration' }
      & Pick<AppConfiguration, 'id' | 'key' | 'value' | 'displayAs'>
    )>>> }
  ) }
);

export type GetAppConfigurationByIdQueryVariables = Exact<{
  input: AppConfigurationInput;
}>;


export type GetAppConfigurationByIdQuery = (
  { __typename?: 'Query' }
  & { appConfigurationBoard: (
    { __typename?: 'GqlAppConfigurationResponse' }
    & Pick<GqlAppConfigurationResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'AppConfiguration' }
      & Pick<AppConfiguration, 'id' | 'key' | 'value' | 'displayAs'>
    )> }
  ) }
);

export type CreateAppConfigurationMutationVariables = Exact<{
  input: CreateAppConfigurationInput;
}>;


export type CreateAppConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { createAppConfigurationBoard: (
    { __typename?: 'GqlAppConfigurationResponse' }
    & Pick<GqlAppConfigurationResponse, 'code' | 'success' | 'message'>
  ) }
);

export type UpdateAppConfigurationMutationVariables = Exact<{
  input: UpdateAppConfigurationInput;
}>;


export type UpdateAppConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { updateAppConfigurationBoard: (
    { __typename?: 'GqlAppConfigurationResponse' }
    & Pick<GqlAppConfigurationResponse, 'code' | 'success' | 'message'>
  ) }
);

export type GetAllChatsQueryVariables = Exact<{
  input: FindChatsBoardInput;
}>;


export type GetAllChatsQuery = (
  { __typename?: 'Query' }
  & { findChatsBoard: (
    { __typename?: 'GqlChatsResponse' }
    & Pick<GqlChatsResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlChatsPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Chat' }
        & Pick<Chat, 'id' | 'unseenMessagesCount' | 'createdAt' | 'updatedAt'>
        & { userChats?: Maybe<Array<Maybe<(
          { __typename?: 'UserChat' }
          & Pick<UserChat, 'chatId'>
          & { owner?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'firstName' | 'lastName' | 'role'>
          )>, recipient?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'firstName' | 'lastName' | 'role'>
          )> }
        )>>> }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type ChatMessageQueryVariables = Exact<{
  input: FindChatMessagesInput;
}>;


export type ChatMessageQuery = (
  { __typename?: 'Query' }
  & { findChatMessagesBoard: (
    { __typename?: 'GqlMessagesResponse' }
    & Pick<GqlMessagesResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlMessagesPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Message' }
        & MessageFragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type MessageFragment = (
  { __typename?: 'Message' }
  & Pick<Message, 'id' | 'content' | 'attachments' | 'chatId' | 'senderId' | 'receiverId' | 'createdAt' | 'updatedAt'>
  & { sender: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'profilePicture' | 'role'>
  ), receiver: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'profilePicture' | 'role'>
  ) }
);

export type GetAllContactMessagesQueryVariables = Exact<{
  input: ContactsBoardInput;
}>;


export type GetAllContactMessagesQuery = (
  { __typename?: 'Query' }
  & { contactsBoard: (
    { __typename?: 'GqlContactsResponse' }
    & Pick<GqlContactsResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlContactsPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'reason' | 'message' | 'isResolved' | 'createdAt'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'profilePicture'>
          & { school?: Maybe<(
            { __typename?: 'School' }
            & Pick<School, 'name' | 'id'>
          )> }
        ) }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type GetContactMessageByIdQueryVariables = Exact<{
  contactId: Scalars['ID'];
}>;


export type GetContactMessageByIdQuery = (
  { __typename?: 'Query' }
  & { contactBoard: (
    { __typename?: 'GqlContactResponse' }
    & Pick<GqlContactResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'reason' | 'message' | 'isResolved' | 'createdAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'bio' | 'lastName' | 'profilePicture' | 'email' | 'phone'>
      ) }
    )> }
  ) }
);

export type UpdateContactMessageMutationVariables = Exact<{
  input: UpdateContactBoardInput;
}>;


export type UpdateContactMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateContactBoard: (
    { __typename?: 'GqlContactResponse' }
    & Pick<GqlContactResponse, 'code' | 'success' | 'message'>
  ) }
);

export type GetAllCurriculumQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCurriculumQuery = (
  { __typename?: 'Query' }
  & { Curriculums: (
    { __typename?: 'GqlCurriculumsArrayResponse' }
    & Pick<GqlCurriculumsArrayResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Curriculum' }
      & Pick<Curriculum, 'id' | 'enName' | 'arName' | 'enDescription' | 'arDescription'>
    )>>> }
  ) }
);

export type GetCurriculumByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCurriculumByIdQuery = (
  { __typename?: 'Query' }
  & { findCurriculum: (
    { __typename?: 'GqlCurriculumResponse' }
    & Pick<GqlCurriculumResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'Curriculum' }
      & Pick<Curriculum, 'id' | 'enName' | 'arName' | 'enDescription' | 'arDescription'>
    )> }
  ) }
);

export type CreateCurriculumMutationVariables = Exact<{
  input: CurriculumInput;
}>;


export type CreateCurriculumMutation = (
  { __typename?: 'Mutation' }
  & { createCurriculum: (
    { __typename?: 'GqlCurriculumResponse' }
    & Pick<GqlCurriculumResponse, 'code' | 'success' | 'message'>
  ) }
);

export type UpdateCurriculumMutationVariables = Exact<{
  input: UpdateCurriculumInput;
}>;


export type UpdateCurriculumMutation = (
  { __typename?: 'Mutation' }
  & { updateCurriculum: (
    { __typename?: 'GqlCurriculumResponse' }
    & Pick<GqlCurriculumResponse, 'code' | 'success' | 'message'>
  ) }
);

export type DeleteCurriculumMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCurriculumMutation = (
  { __typename?: 'Mutation' }
  & { deleteCurriculum: (
    { __typename?: 'GqlBooleanResponse' }
    & Pick<GqlBooleanResponse, 'code' | 'success' | 'message'>
  ) }
);

export type GetAllJobsQueryVariables = Exact<{
  input: JobsBoardInput;
}>;


export type GetAllJobsQuery = (
  { __typename?: 'Query' }
  & { jobsBoard: (
    { __typename?: 'GqlJobsResponse' }
    & Pick<GqlJobsResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlJobsPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Job' }
        & JobFragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type GetJobByIdQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type GetJobByIdQuery = (
  { __typename?: 'Query' }
  & { JobBoard: (
    { __typename?: 'GqlJobResponse' }
    & Pick<GqlJobResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'Job' }
      & JobFragment
    )> }
  ) }
);

export type LoginMutationVariables = Exact<{
  input: LoginBoardInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { loginBoard: (
    { __typename?: 'GqlUserResponse' }
    & Pick<GqlUserResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'token' | 'firstName' | 'role'>
      & { securityGroup?: Maybe<(
        { __typename?: 'SecurityGroup' }
        & SecurityGroupFragment
      )> }
    )> }
  ) }
);

export type SendNotificationMutationVariables = Exact<{
  input: SendNotificationBoardInput;
}>;


export type SendNotificationMutation = (
  { __typename?: 'Mutation' }
  & { sendNotificationBoard: (
    { __typename?: 'GqlBooleanResponse' }
    & Pick<GqlBooleanResponse, 'code' | 'success' | 'message'>
  ) }
);

export type GetAllUsersQueryVariables = Exact<{
  input: UsersBoardInput;
}>;


export type GetAllUsersQuery = (
  { __typename?: 'Query' }
  & { usersBoard: (
    { __typename?: 'GqlUsersResponse' }
    & Pick<GqlUsersResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlUsersPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  model: FileModelEnum;
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile: (
    { __typename?: 'GqlFileResponse' }
    & Pick<GqlFileResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'relativeDiskDestination' | 'name' | 'encoding' | 'mimetype' | 'sizeInBytes'>
    )> }
  ) }
);

export type GetAllNotificationsQueryVariables = Exact<{
  input: MyNotificationsInput;
}>;


export type GetAllNotificationsQuery = (
  { __typename?: 'Query' }
  & { myNotifications: (
    { __typename?: 'GqlNotificationsResponse' }
    & Pick<GqlNotificationsResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlNotificationsPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Notification' }
        & Pick<Notification, 'id' | 'type' | 'thumbnail' | 'enTitle' | 'arTitle' | 'enBody' | 'arBody' | 'createdAt' | 'updatedAt'>
        & { sender?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
        )> }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type GetNotificationByIdQueryVariables = Exact<{
  notificationId: Scalars['ID'];
}>;


export type GetNotificationByIdQuery = (
  { __typename?: 'Query' }
  & { myNotification: (
    { __typename?: 'GqlNotificationResponse' }
    & Pick<GqlNotificationResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id' | 'type' | 'thumbnail' | 'enTitle' | 'arTitle' | 'enBody' | 'arBody' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type GetAllReportsQueryVariables = Exact<{
  input: ReportsFilterInput;
}>;


export type GetAllReportsQuery = (
  { __typename?: 'Query' }
  & { getReports: (
    { __typename?: 'GqlReportsResponse' }
    & Pick<GqlReportsResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlReportsPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Report' }
        & Pick<Report, 'id' | 'reporterId' | 'reportedId' | 'jobId' | 'content' | 'createdAt' | 'updatedAt'>
        & { reporter: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'bio' | 'lastName' | 'email'>
          & { school?: Maybe<(
            { __typename?: 'School' }
            & Pick<School, 'name'>
          )> }
        ), job?: Maybe<(
          { __typename?: 'Job' }
          & Pick<Job, 'id' | 'grade' | 'gender' | 'status' | 'description'>
          & { subject: (
            { __typename?: 'Subject' }
            & Pick<Subject, 'id' | 'enName'>
          ), school: (
            { __typename?: 'School' }
            & Pick<School, 'id' | 'name' | 'phone' | 'landlineNumber' | 'email'>
          ) }
        )>, reported?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'bio' | 'lastName' | 'email'>
          & { school?: Maybe<(
            { __typename?: 'School' }
            & Pick<School, 'name'>
          )> }
        )> }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type GetReportByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetReportByIdQuery = (
  { __typename?: 'Query' }
  & { getReportById: (
    { __typename?: 'GqlReportResponse' }
    & Pick<GqlReportResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'Report' }
      & Pick<Report, 'id' | 'reporterId' | 'reportedId' | 'jobId' | 'content' | 'createdAt' | 'updatedAt'>
      & { reporter: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'bio' | 'lastName' | 'email'>
        & { school?: Maybe<(
          { __typename?: 'School' }
          & Pick<School, 'name'>
        )> }
      ), job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'grade' | 'gender' | 'status' | 'description'>
        & { subject: (
          { __typename?: 'Subject' }
          & Pick<Subject, 'id' | 'enName'>
        ), school: (
          { __typename?: 'School' }
          & Pick<School, 'id' | 'name' | 'phone' | 'landlineNumber' | 'email'>
        ) }
      )>, reported?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'bio' | 'lastName' | 'email'>
        & { school?: Maybe<(
          { __typename?: 'School' }
          & Pick<School, 'name'>
        )> }
      )> }
    )> }
  ) }
);

export type GetAllChatReportsQueryVariables = Exact<{
  input: ChatReportFilterInput;
}>;


export type GetAllChatReportsQuery = (
  { __typename?: 'Query' }
  & { getChatReportsByFilter: (
    { __typename?: 'GqlUserChatsResponse' }
    & Pick<GqlUserChatsResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlUserChatsPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'UserChat' }
        & Pick<UserChat, 'id' | 'userId' | 'chatId' | 'status' | 'recipientId' | 'report' | 'lastOpened'>
        & { owner?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'bio' | 'email'>
          & { school?: Maybe<(
            { __typename?: 'School' }
            & Pick<School, 'id' | 'name'>
          )> }
        )>, recipient?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'bio' | 'email'>
          & { school?: Maybe<(
            { __typename?: 'School' }
            & Pick<School, 'id' | 'name'>
          )> }
        )> }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type DeleteReportMutationVariables = Exact<{
  input: DeleteReportInputs;
}>;


export type DeleteReportMutation = (
  { __typename?: 'Mutation' }
  & { deleteReport: (
    { __typename?: 'GqlBooleanResponse' }
    & Pick<GqlBooleanResponse, 'code' | 'success' | 'message'>
  ) }
);

export type DeleteChatReportMutationVariables = Exact<{
  input: DeleteReportInputs;
}>;


export type DeleteChatReportMutation = (
  { __typename?: 'Mutation' }
  & { deleteChatReport: (
    { __typename?: 'GqlBooleanResponse' }
    & Pick<GqlBooleanResponse, 'code' | 'success' | 'message'>
  ) }
);

export type GetAllJoinRequestsQueryVariables = Exact<{
  input: ViewUsersRequestsInput;
}>;


export type GetAllJoinRequestsQuery = (
  { __typename?: 'Query' }
  & { viewUsersRequestsBoard: (
    { __typename?: 'GqlUsersResponse' }
    & Pick<GqlUsersResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlUsersPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & { school?: Maybe<(
          { __typename?: 'School' }
          & SchoolFragment
        )>, teacher?: Maybe<(
          { __typename?: 'Teacher' }
          & Pick<Teacher, 'teacherId' | 'experience' | 'grades' | 'cv' | 'idDocument' | 'certificates' | 'updatedAt' | 'createdAt'>
          & { curriculums: Array<(
            { __typename?: 'Curriculum' }
            & CurriculumFragment
          )>, subjects: Array<(
            { __typename?: 'Subject' }
            & Pick<Subject, 'id' | 'enName' | 'arName' | 'enDescription' | 'arDescription'>
            & { jobs?: Maybe<Array<Maybe<(
              { __typename?: 'Job' }
              & JobFragment
            )>>> }
          )> }
        )> }
        & UserFragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type ReplayJoinRequestMutationVariables = Exact<{
  input: UpdateUserStatusInput;
}>;


export type ReplayJoinRequestMutation = (
  { __typename?: 'Mutation' }
  & { updateUserStatus: (
    { __typename?: 'GqlUserResponse' }
    & Pick<GqlUserResponse, 'code' | 'success' | 'message'>
  ) }
);

export type SchoolFragment = (
  { __typename?: 'School' }
  & Pick<School, 'id' | 'name' | 'schoolAdminId' | 'phone' | 'landlineNumber' | 'email' | 'schoolType' | 'profilePicture' | 'grades' | 'gender' | 'certificates' | 'arAddress' | 'enAddress' | 'createdAt' | 'updatedAt'>
  & { curriculums?: Maybe<Array<Maybe<(
    { __typename?: 'Curriculum' }
    & CurriculumFragment
  )>>>, location?: Maybe<(
    { __typename?: 'LocationType' }
    & LocationTypeFragment
  )>, user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'isBlocked'>
    & { city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name_ar' | 'name_en'>
    )> }
  ) }
);

export type TeacherFragment = (
  { __typename?: 'Teacher' }
  & Pick<Teacher, 'teacherId' | 'experience' | 'grades' | 'cv' | 'idDocument' | 'certificates' | 'updatedAt' | 'createdAt'>
  & { curriculums: Array<(
    { __typename?: 'Curriculum' }
    & CurriculumFragment
  )>, subjects: Array<(
    { __typename?: 'Subject' }
    & Pick<Subject, 'id' | 'enName' | 'arName' | 'enDescription' | 'arDescription'>
    & { jobs?: Maybe<Array<Maybe<(
      { __typename?: 'Job' }
      & JobFragment
    )>>> }
  )>, user: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type CurriculumFragment = (
  { __typename?: 'Curriculum' }
  & Pick<Curriculum, 'id' | 'enName' | 'arName' | 'enDescription' | 'arDescription'>
);

export type JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'grade' | 'gender' | 'minExperience' | 'maxExperience' | 'requiredApplicantsNumber' | 'status' | 'description' | 'completeReason' | 'requirements' | 'startDate' | 'endDate' | 'salary' | 'createdAt' | 'updatedAt' | 'numberOfDays' | 'total' | 'numberOfApplicants' | 'appliedAt'>
  & { school: (
    { __typename?: 'School' }
    & SchoolFragment
  ), applicantsAllowedCities: Array<(
    { __typename?: 'City' }
    & Pick<City, 'id' | 'name_ar' | 'name_en'>
  )>, subject: (
    { __typename?: 'Subject' }
    & SubjectFragment
  ), curriculum: (
    { __typename?: 'Curriculum' }
    & CurriculumFragment
  ) }
);

export type ReportFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'reporterId' | 'reportedId' | 'jobId' | 'content' | 'createdAt' | 'updatedAt'>
  & { reporter: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'bio' | 'lastName' | 'email'>
  ), reported?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'bio' | 'lastName' | 'email'>
  )> }
);

export type GetJoinRequestByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetJoinRequestByIdQuery = (
  { __typename?: 'Query' }
  & { viewUserRequestBoard: (
    { __typename?: 'GqlUserResponse' }
    & Pick<GqlUserResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'User' }
      & { school?: Maybe<(
        { __typename?: 'School' }
        & SchoolFragment
      )>, teacher?: Maybe<(
        { __typename?: 'Teacher' }
        & Pick<Teacher, 'teacherId' | 'experience' | 'grades' | 'cv' | 'idDocument' | 'certificates' | 'updatedAt' | 'createdAt'>
        & { curriculums: Array<(
          { __typename?: 'Curriculum' }
          & CurriculumFragment
        )>, subjects: Array<(
          { __typename?: 'Subject' }
          & Pick<Subject, 'id' | 'enName' | 'arName' | 'enDescription' | 'arDescription'>
          & { jobs?: Maybe<Array<Maybe<(
            { __typename?: 'Job' }
            & JobFragment
          )>>> }
        )> }
      )> }
      & UserFragment
    )> }
  ) }
);

export type SubjectFragment = (
  { __typename?: 'Subject' }
  & Pick<Subject, 'id' | 'enName' | 'arName' | 'enDescription' | 'arDescription' | 'createdAt' | 'updatedAt'>
);

export type GetAllEditRequestsQueryVariables = Exact<{
  input: DocumentsUpdatesBoardInput;
}>;


export type GetAllEditRequestsQuery = (
  { __typename?: 'Query' }
  & { documentsUpdatesBoard: (
    { __typename?: 'GqlDocumentUpdatesResponse' }
    & Pick<GqlDocumentUpdatesResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlDocumentUpdatesPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentUpdate' }
        & Pick<DocumentUpdate, 'id' | 'documents' | 'status' | 'documentType' | 'updateReason' | 'rejectionReasons' | 'updatedAt' | 'createdAt'>
        & { user: (
          { __typename?: 'User' }
          & UserFragment
        ) }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type ReplayEditRequestMutationVariables = Exact<{
  input: UpdateUserDocumentsBoardInput;
}>;


export type ReplayEditRequestMutation = (
  { __typename?: 'Mutation' }
  & { updateUserDocumentsBoard: (
    { __typename?: 'GqlDocumentUpdateResponse' }
    & Pick<GqlDocumentUpdateResponse, 'code' | 'success' | 'message'>
  ) }
);

export type GetEditRequestByIdQueryVariables = Exact<{
  documentUpdateId: Scalars['ID'];
}>;


export type GetEditRequestByIdQuery = (
  { __typename?: 'Query' }
  & { documentUpdateBoard: (
    { __typename?: 'GqlDocumentUpdateResponse' }
    & Pick<GqlDocumentUpdateResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'DocumentUpdate' }
      & Pick<DocumentUpdate, 'id' | 'documents' | 'status' | 'documentType' | 'updateReason' | 'rejectionReasons' | 'updatedAt' | 'createdAt'>
      & { user: (
        { __typename?: 'User' }
        & UserFragment
      ) }
    )> }
  ) }
);

export type GetAllReviewsQueryVariables = Exact<{
  input: ReviewsBoardInput;
}>;


export type GetAllReviewsQuery = (
  { __typename?: 'Query' }
  & { reviewsBoard: (
    { __typename?: 'GqlReviewsResponse' }
    & Pick<GqlReviewsResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlReviewsPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Review' }
        & ReviewFragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type GetReviewByIdQueryVariables = Exact<{
  reviewId: Scalars['ID'];
}>;


export type GetReviewByIdQuery = (
  { __typename?: 'Query' }
  & { reviewBoard: (
    { __typename?: 'GqlReviewResponse' }
    & Pick<GqlReviewResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'Review' }
      & ReviewFragment
    )> }
  ) }
);

export type DeleteReviewMutationVariables = Exact<{
  reviewId: Scalars['ID'];
}>;


export type DeleteReviewMutation = (
  { __typename?: 'Mutation' }
  & { deleteReviewBoard: (
    { __typename?: 'GqlBasicResponse' }
    & Pick<GqlBasicResponse, 'code' | 'success' | 'message'>
  ) }
);

export type ReviewFragment = (
  { __typename?: 'Review' }
  & Pick<Review, 'id' | 'rate' | 'content' | 'createdAt' | 'updatedAt'>
  & { reviewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'bio' | 'lastName' | 'email'>
    & { school?: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'name'>
    )> }
  ), teacher?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'bio' | 'lastName' | 'email'>
  )>, school?: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'id' | 'name' | 'phone' | 'profilePicture'>
  )> }
);

export type GetAllSecurityGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllSecurityGroupsQuery = (
  { __typename?: 'Query' }
  & { securityGroups: (
    { __typename?: 'GqlSecurityGroupsArrayResponse' }
    & Pick<GqlSecurityGroupsArrayResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'SecurityGroup' }
      & SecurityGroupFragment
    )>>> }
  ) }
);

export type GetSecurityGroupByIdQueryVariables = Exact<{
  filter: SecurityGroupFilterInput;
}>;


export type GetSecurityGroupByIdQuery = (
  { __typename?: 'Query' }
  & { securityGroup: (
    { __typename?: 'GqlSecurityGroupResponse' }
    & Pick<GqlSecurityGroupResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'SecurityGroup' }
      & SecurityGroupFragment
    )> }
  ) }
);

export type CreateSecurityGroupMutationVariables = Exact<{
  input: CreatSecurityGroupInput;
}>;


export type CreateSecurityGroupMutation = (
  { __typename?: 'Mutation' }
  & { createSecurityGroup: (
    { __typename?: 'GqlSecurityGroupResponse' }
    & Pick<GqlSecurityGroupResponse, 'code' | 'success' | 'message'>
  ) }
);

export type UpdateSecurityGroupMutationVariables = Exact<{
  input: UpdateSecurityGroupInput;
}>;


export type UpdateSecurityGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateSecurityGroup: (
    { __typename?: 'GqlSecurityGroupResponse' }
    & Pick<GqlSecurityGroupResponse, 'code' | 'success' | 'message'>
  ) }
);

export type DeleteSecurityGroupMutationVariables = Exact<{
  securityGrouptId: Scalars['String'];
}>;


export type DeleteSecurityGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteSecurityGroup: (
    { __typename?: 'GqlSecurityGroupResponse' }
    & Pick<GqlSecurityGroupResponse, 'code' | 'success' | 'message'>
  ) }
);

export type GetAllPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPermissionsQuery = (
  { __typename?: 'Query' }
  & { securityGroupPermissions: (
    { __typename?: 'GqlStringsArrayResponse' }
    & Pick<GqlStringsArrayResponse, 'data' | 'code' | 'success' | 'message'>
  ) }
);

export type GetUsersAssignedToRoleQueryVariables = Exact<{
  filter: SecurityGroupFilterInput;
}>;


export type GetUsersAssignedToRoleQuery = (
  { __typename?: 'Query' }
  & { securityGroupUsers: (
    { __typename?: 'GqlUsersArrayResponse' }
    & Pick<GqlUsersArrayResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    )>>> }
  ) }
);

export type GetAllRoleUsersQueryVariables = Exact<{
  filter: SecurityGroupFilterInput;
}>;


export type GetAllRoleUsersQuery = (
  { __typename?: 'Query' }
  & { securityGroupUsers: (
    { __typename?: 'GqlUsersArrayResponse' }
    & Pick<GqlUsersArrayResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & UserFragment
    )>>> }
  ) }
);

export type CreateAdminMutationVariables = Exact<{
  input: CreateAdminInput;
}>;


export type CreateAdminMutation = (
  { __typename?: 'Mutation' }
  & { createAdmin: (
    { __typename?: 'GqlUserResponse' }
    & Pick<GqlUserResponse, 'code' | 'success' | 'message'>
  ) }
);

export type UpdateAdminMutationVariables = Exact<{
  input: UpdateAdminInput;
}>;


export type UpdateAdminMutation = (
  { __typename?: 'Mutation' }
  & { updateAdmin: (
    { __typename?: 'GqlUserResponse' }
    & Pick<GqlUserResponse, 'code' | 'success' | 'message'>
  ) }
);

export type DeleteAdminMutationVariables = Exact<{
  input: DeleteAdminInput;
}>;


export type DeleteAdminMutation = (
  { __typename?: 'Mutation' }
  & { deleteAdmin: (
    { __typename?: 'GqlUserResponse' }
    & Pick<GqlUserResponse, 'code' | 'success' | 'message'>
  ) }
);

export type GetAdminByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAdminByIdQuery = (
  { __typename?: 'Query' }
  & { getAdminById: (
    { __typename?: 'GqlUserResponse' }
    & Pick<GqlUserResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    )> }
  ) }
);

export type GetAllSubjectQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllSubjectQuery = (
  { __typename?: 'Query' }
  & { Subjects: (
    { __typename?: 'GqlSubjectsArrayResponse' }
    & Pick<GqlSubjectsArrayResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Subject' }
      & Pick<Subject, 'id' | 'enName' | 'arName' | 'enDescription' | 'arDescription' | 'createdAt'>
    )>>> }
  ) }
);

export type GetSubjectByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSubjectByIdQuery = (
  { __typename?: 'Query' }
  & { findSubject: (
    { __typename?: 'GqlSubjectResponse' }
    & Pick<GqlSubjectResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'Subject' }
      & Pick<Subject, 'id' | 'enName' | 'arName' | 'enDescription' | 'arDescription' | 'createdAt'>
    )> }
  ) }
);

export type CreateSubjectMutationVariables = Exact<{
  input: SubjectInput;
}>;


export type CreateSubjectMutation = (
  { __typename?: 'Mutation' }
  & { createSubject: (
    { __typename?: 'GqlSubjectResponse' }
    & Pick<GqlSubjectResponse, 'code' | 'success' | 'message'>
  ) }
);

export type UpdateSubjectMutationVariables = Exact<{
  input: UpdateSubjectInput;
}>;


export type UpdateSubjectMutation = (
  { __typename?: 'Mutation' }
  & { updateSubject: (
    { __typename?: 'GqlSubjectResponse' }
    & Pick<GqlSubjectResponse, 'code' | 'success' | 'message'>
  ) }
);

export type DeleteSubjectMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSubjectMutation = (
  { __typename?: 'Mutation' }
  & { deleteSubject: (
    { __typename?: 'GqlBasicResponse' }
    & Pick<GqlBasicResponse, 'code' | 'success' | 'message'>
  ) }
);

export type GetAllSubscriptionPlansQueryVariables = Exact<{
  input: GetSubscriptionPlansInput;
}>;


export type GetAllSubscriptionPlansQuery = (
  { __typename?: 'Query' }
  & { getSubscriptionPlansBoard: (
    { __typename?: 'GqlSubscriptionPlansResponse' }
    & Pick<GqlSubscriptionPlansResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlSubscriptionPlansPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'SubscriptionPlan' }
        & SubscriptionPlanFragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type GetSubscriptionPlansByIdQueryVariables = Exact<{
  subscriptionPlanId: Scalars['ID'];
}>;


export type GetSubscriptionPlansByIdQuery = (
  { __typename?: 'Query' }
  & { subscriptionPlan: (
    { __typename?: 'GqlSubscriptionPlanResponse' }
    & Pick<GqlSubscriptionPlanResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'SubscriptionPlan' }
      & SubscriptionPlanFragment
    )> }
  ) }
);

export type SubscriptionPlanFragment = (
  { __typename?: 'SubscriptionPlan' }
  & Pick<SubscriptionPlan, 'id' | 'arDescription' | 'enDescription' | 'arName' | 'enName' | 'slug' | 'price' | 'inAppPurchaseId' | 'isActive' | 'numberOfDays' | 'createdAt' | 'updatedAt'>
);

export type CreateSubscriptionPlanMutationVariables = Exact<{
  input: CreateSubscriptionPlanBoardInput;
}>;


export type CreateSubscriptionPlanMutation = (
  { __typename?: 'Mutation' }
  & { createSubscriptionPlanBoard: (
    { __typename?: 'GqlSubscriptionPlanResponse' }
    & Pick<GqlSubscriptionPlanResponse, 'code' | 'success' | 'message'>
  ) }
);

export type UpdateSubscriptionPlanMutationVariables = Exact<{
  input: UpdateSubscriptionPlanBoardInput;
}>;


export type UpdateSubscriptionPlanMutation = (
  { __typename?: 'Mutation' }
  & { updateSubscriptionPlanBoard: (
    { __typename?: 'GqlSubscriptionPlanResponse' }
    & Pick<GqlSubscriptionPlanResponse, 'code' | 'success' | 'message'>
  ) }
);

export type DeleteSubscriptionPlanMutationVariables = Exact<{
  subscriptionPlanId: Scalars['ID'];
}>;


export type DeleteSubscriptionPlanMutation = (
  { __typename?: 'Mutation' }
  & { deleteSubscriptionPlanBoard: (
    { __typename?: 'GqlBasicResponse' }
    & Pick<GqlBasicResponse, 'code' | 'success' | 'message'>
  ) }
);

export type GetAllTransactionQueryVariables = Exact<{
  input: TransactionsBoardInput;
}>;


export type GetAllTransactionQuery = (
  { __typename?: 'Query' }
  & { transactionsBoard: (
    { __typename?: 'GqlTransactionsResponse' }
    & Pick<GqlTransactionsResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'GqlTransactionsPagination' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Transaction' }
        & TransactionFragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'totalCount' | 'totalPages' | 'page' | 'limit' | 'hasNext'>
      ) }
    )> }
  ) }
);

export type GetTransactionByIdQueryVariables = Exact<{
  transactionId: Scalars['ID'];
}>;


export type GetTransactionByIdQuery = (
  { __typename?: 'Query' }
  & { transactionBoard: (
    { __typename?: 'GqlTransactionResponse' }
    & Pick<GqlTransactionResponse, 'code' | 'success' | 'message'>
    & { data?: Maybe<(
      { __typename?: 'Transaction' }
      & TransactionFragment
    )> }
  ) }
);

export type TransactionFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'amount' | 'startsAt' | 'expiresIn' | 'transactionType' | 'createdAt' | 'updatedAt'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )>, payment: (
    { __typename?: 'PaymentUseCase' }
    & PaymentUseCaseFragment
  ) }
);

export type PaymentUseCaseFragment = (
  { __typename?: 'PaymentUseCase' }
  & Pick<PaymentUseCase, 'paymentMethod' | 'paymentStatus' | 'failureReason' | 'gatewayOrderId'>
  & { itemSnapShot: (
    { __typename?: 'SubscriptionPlan' }
    & Pick<SubscriptionPlan, 'id' | 'arDescription' | 'enDescription' | 'arName' | 'enName' | 'slug' | 'price' | 'inAppPurchaseId' | 'numberOfDays' | 'createdAt' | 'updatedAt'>
  ) }
);

export const CardFragmentDoc = gql`
    fragment Card on Card {
  id
  expMonth
  expYear
  last4
  brand
  createdAt
  updatedAt
}
    `;
export const MessageFragmentDoc = gql`
    fragment Message on Message {
  id
  content
  attachments
  chatId
  sender {
    id
    firstName
    lastName
    profilePicture
    role
  }
  senderId
  receiver {
    id
    firstName
    lastName
    profilePicture
    role
  }
  receiverId
  createdAt
  updatedAt
}
    `;
export const CurriculumFragmentDoc = gql`
    fragment Curriculum on Curriculum {
  id
  enName
  arName
  enDescription
  arDescription
}
    `;
export const LocationTypeFragmentDoc = gql`
    fragment LocationType on LocationType {
  type
  coordinates
}
    `;
export const SchoolFragmentDoc = gql`
    fragment School on School {
  id
  curriculums {
    ...Curriculum
  }
  name
  schoolAdminId
  phone
  landlineNumber
  email
  schoolType
  profilePicture
  grades
  gender
  certificates
  location {
    ...LocationType
  }
  arAddress
  enAddress
  createdAt
  updatedAt
  user {
    id
    isBlocked
    city {
      id
      name_ar
      name_en
    }
  }
}
    ${CurriculumFragmentDoc}
${LocationTypeFragmentDoc}`;
export const SubjectFragmentDoc = gql`
    fragment Subject on Subject {
  id
  enName
  arName
  enDescription
  arDescription
  createdAt
  updatedAt
}
    `;
export const JobFragmentDoc = gql`
    fragment Job on Job {
  id
  grade
  gender
  minExperience
  maxExperience
  requiredApplicantsNumber
  status
  description
  completeReason
  requirements
  startDate
  endDate
  salary
  school {
    ...School
  }
  applicantsAllowedCities {
    id
    name_ar
    name_en
  }
  createdAt
  updatedAt
  numberOfDays
  total
  numberOfApplicants
  subject {
    ...Subject
  }
  curriculum {
    ...Curriculum
  }
  appliedAt
}
    ${SchoolFragmentDoc}
${SubjectFragmentDoc}
${CurriculumFragmentDoc}`;
export const SecurityGroupFragmentDoc = gql`
    fragment SecurityGroup on SecurityGroup {
  id
  groupName
  description
  permissions
}
    `;
export const LastLoginDetailsFragmentDoc = gql`
    fragment LastLoginDetails on LastLoginDetails {
  lastLoginAt
  lastLoginDevice
  platformDetails
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  firstName
  bio
  lastName
  slug
  rejectionReasons
  email
  status
  isEmailVerified
  phone
  unverifiedPhone
  gender
  birthDate
  role
  arAddress
  enAddress
  location {
    ...LocationType
  }
  profilePicture
  isBlocked
  favLang
  securityGroup {
    ...SecurityGroup
  }
  notificationManager {
    NEW_CONTACT_MESSAGE
    NEW_FOLLOWER
    NEW_JOB_POSTED
    NEW_JOP_APPLICANT
  }
  token
  lastLoginDetails {
    ...LastLoginDetails
  }
  school {
    ...School
  }
  teacher {
    teacherId
    experience
    grades
    curriculums {
      id
      enName
      arName
    }
    cv
    idDocument
    certificates
    subjects {
      id
      enName
      enName
    }
    isAvailable
    avgRate
    noOfReviews
    updatedAt
    createdAt
    reviewed
  }
  createdAt
  updatedAt
  stripeCustomerId
  isComplete
  awayFromCurrentUserBy
  subscriptionStatus
  saved
  city {
    id
    name_ar
    name_en
  }
}
    ${LocationTypeFragmentDoc}
${SecurityGroupFragmentDoc}
${LastLoginDetailsFragmentDoc}
${SchoolFragmentDoc}`;
export const TeacherFragmentDoc = gql`
    fragment Teacher on Teacher {
  teacherId
  experience
  grades
  curriculums {
    ...Curriculum
  }
  cv
  idDocument
  certificates
  updatedAt
  createdAt
  subjects {
    id
    enName
    arName
    jobs {
      ...Job
    }
    enDescription
    arDescription
  }
  user {
    ...User
  }
}
    ${CurriculumFragmentDoc}
${JobFragmentDoc}
${UserFragmentDoc}`;
export const ReportFragmentDoc = gql`
    fragment Report on Report {
  reporterId
  reporter {
    id
    firstName
    bio
    lastName
    email
  }
  reportedId
  reported {
    id
    firstName
    bio
    lastName
    email
  }
  jobId
  content
  createdAt
  updatedAt
}
    `;
export const ReviewFragmentDoc = gql`
    fragment Review on Review {
  id
  reviewer {
    id
    firstName
    bio
    lastName
    email
    school {
      name
    }
  }
  rate
  content
  createdAt
  updatedAt
  teacher {
    id
    firstName
    bio
    lastName
    email
  }
  school {
    id
    name
    phone
    profilePicture
  }
}
    `;
export const SubscriptionPlanFragmentDoc = gql`
    fragment SubscriptionPlan on SubscriptionPlan {
  id
  arDescription
  enDescription
  arName
  enName
  slug
  price
  inAppPurchaseId
  isActive
  numberOfDays
  createdAt
  updatedAt
}
    `;
export const PaymentUseCaseFragmentDoc = gql`
    fragment PaymentUseCase on PaymentUseCase {
  itemSnapShot {
    id
    arDescription
    enDescription
    arName
    enName
    slug
    price
    inAppPurchaseId
    numberOfDays
    createdAt
    updatedAt
  }
  paymentMethod
  paymentStatus
  failureReason
  gatewayOrderId
}
    `;
export const TransactionFragmentDoc = gql`
    fragment Transaction on Transaction {
  id
  user {
    ...User
  }
  amount
  startsAt
  expiresIn
  transactionType
  payment {
    ...PaymentUseCase
  }
  createdAt
  updatedAt
}
    ${UserFragmentDoc}
${PaymentUseCaseFragmentDoc}`;
export const GetAllFaqDocument = gql`
    query GetAllFAQ($input: FaqsBoardInput!) {
  faqsBoard(input: $input) {
    data {
      items {
        id
        enQuestion
        arQuestion
        enAnswer
        arAnswer
        isActive
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllFaqGQL extends Apollo.Query<GetAllFaqQuery, GetAllFaqQueryVariables> {
    document = GetAllFaqDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSingleFaqDocument = gql`
    query GetSingleFAQ($faqId: String!) {
  faqBoard(faqId: $faqId) {
    data {
      id
      enQuestion
      arQuestion
      enAnswer
      arAnswer
      isActive
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSingleFaqGQL extends Apollo.Query<GetSingleFaqQuery, GetSingleFaqQueryVariables> {
    document = GetSingleFaqDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateFaqDocument = gql`
    mutation CreateFaq($input: CreateFaqInput!) {
  createFaqBoard(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateFaqGQL extends Apollo.Mutation<CreateFaqMutation, CreateFaqMutationVariables> {
    document = CreateFaqDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFaqDocument = gql`
    mutation UpdateFaq($input: UpdateFaqInput!) {
  updateFaqBoard(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFaqGQL extends Apollo.Mutation<UpdateFaqMutation, UpdateFaqMutationVariables> {
    document = UpdateFaqDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFaqDocument = gql`
    mutation DeleteFaq($faqId: String!) {
  deleteFaqBoard(faqId: $faqId) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFaqGQL extends Apollo.Mutation<DeleteFaqMutation, DeleteFaqMutationVariables> {
    document = DeleteFaqDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllTeachersDocument = gql`
    query GetAllTeachers($input: GetTeachersBoardInput!) {
  TeachersBoard(input: $input) {
    data {
      items {
        ...User
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllTeachersGQL extends Apollo.Query<GetAllTeachersQuery, GetAllTeachersQueryVariables> {
    document = GetAllTeachersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllSchoolsDocument = gql`
    query GetAllSchools($input: SchoolsFiltersAndPagination!) {
  schoolsBoard(input: $input) {
    data {
      items {
        ...School
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    ${SchoolFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllSchoolsGQL extends Apollo.Query<GetAllSchoolsQuery, GetAllSchoolsQueryVariables> {
    document = GetAllSchoolsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTeacherByIdDocument = gql`
    query GetTeacherById($input: GetTeacherByIdInput!) {
  getTeacherByIdBoard(input: $input) {
    data {
      ...Teacher
    }
    code
    success
    message
  }
}
    ${TeacherFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTeacherByIdGQL extends Apollo.Query<GetTeacherByIdQuery, GetTeacherByIdQueryVariables> {
    document = GetTeacherByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSchoolByIdDocument = gql`
    query getSchoolById($input: FindSchoolByIdInput!) {
  findSchoolByIdBoard(input: $input) {
    data {
      ...School
    }
    code
    success
    message
  }
}
    ${SchoolFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSchoolByIdGQL extends Apollo.Query<GetSchoolByIdQuery, GetSchoolByIdQueryVariables> {
    document = GetSchoolByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindUserDocument = gql`
    query findUser($id: String!) {
  findUser(id: $id) {
    data {
      ...User
    }
    code
    success
    message
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FindUserGQL extends Apollo.Query<FindUserQuery, FindUserQueryVariables> {
    document = FindUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BlockUserDocument = gql`
    mutation BlockUser($input: BlockUserInput!) {
  blockUser(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockUserGQL extends Apollo.Mutation<BlockUserMutation, BlockUserMutationVariables> {
    document = BlockUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllAppConfigurationsDocument = gql`
    query GetAllAppConfigurations {
  appConfigurationsBoard {
    data {
      id
      key
      value
      displayAs
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllAppConfigurationsGQL extends Apollo.Query<GetAllAppConfigurationsQuery, GetAllAppConfigurationsQueryVariables> {
    document = GetAllAppConfigurationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAppConfigurationByIdDocument = gql`
    query GetAppConfigurationById($input: AppConfigurationInput!) {
  appConfigurationBoard(input: $input) {
    data {
      id
      key
      value
      displayAs
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAppConfigurationByIdGQL extends Apollo.Query<GetAppConfigurationByIdQuery, GetAppConfigurationByIdQueryVariables> {
    document = GetAppConfigurationByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAppConfigurationDocument = gql`
    mutation CreateAppConfiguration($input: CreateAppConfigurationInput!) {
  createAppConfigurationBoard(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAppConfigurationGQL extends Apollo.Mutation<CreateAppConfigurationMutation, CreateAppConfigurationMutationVariables> {
    document = CreateAppConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAppConfigurationDocument = gql`
    mutation UpdateAppConfiguration($input: UpdateAppConfigurationInput!) {
  updateAppConfigurationBoard(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAppConfigurationGQL extends Apollo.Mutation<UpdateAppConfigurationMutation, UpdateAppConfigurationMutationVariables> {
    document = UpdateAppConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllChatsDocument = gql`
    query GetAllChats($input: FindChatsBoardInput!) {
  findChatsBoard(input: $input) {
    data {
      items {
        id
        unseenMessagesCount
        createdAt
        updatedAt
        userChats {
          chatId
          owner {
            firstName
            lastName
            role
          }
          recipient {
            firstName
            lastName
            role
          }
        }
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllChatsGQL extends Apollo.Query<GetAllChatsQuery, GetAllChatsQueryVariables> {
    document = GetAllChatsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChatMessageDocument = gql`
    query chatMessage($input: FindChatMessagesInput!) {
  findChatMessagesBoard(input: $input) {
    data {
      items {
        ...Message
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    ${MessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChatMessageGQL extends Apollo.Query<ChatMessageQuery, ChatMessageQueryVariables> {
    document = ChatMessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllContactMessagesDocument = gql`
    query GetAllContactMessages($input: ContactsBoardInput!) {
  contactsBoard(input: $input) {
    data {
      items {
        id
        reason
        message
        isResolved
        createdAt
        user {
          id
          firstName
          lastName
          profilePicture
          school {
            name
            id
          }
        }
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllContactMessagesGQL extends Apollo.Query<GetAllContactMessagesQuery, GetAllContactMessagesQueryVariables> {
    document = GetAllContactMessagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContactMessageByIdDocument = gql`
    query GetContactMessageById($contactId: ID!) {
  contactBoard(contactId: $contactId) {
    data {
      id
      reason
      message
      isResolved
      createdAt
      user {
        id
        firstName
        bio
        lastName
        profilePicture
        email
        phone
      }
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContactMessageByIdGQL extends Apollo.Query<GetContactMessageByIdQuery, GetContactMessageByIdQueryVariables> {
    document = GetContactMessageByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateContactMessageDocument = gql`
    mutation UpdateContactMessage($input: UpdateContactBoardInput!) {
  updateContactBoard(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateContactMessageGQL extends Apollo.Mutation<UpdateContactMessageMutation, UpdateContactMessageMutationVariables> {
    document = UpdateContactMessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllCurriculumDocument = gql`
    query GetAllCurriculum {
  Curriculums {
    data {
      id
      enName
      arName
      enDescription
      arDescription
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllCurriculumGQL extends Apollo.Query<GetAllCurriculumQuery, GetAllCurriculumQueryVariables> {
    document = GetAllCurriculumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCurriculumByIdDocument = gql`
    query GetCurriculumById($id: ID!) {
  findCurriculum(id: $id) {
    data {
      id
      enName
      arName
      enDescription
      arDescription
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCurriculumByIdGQL extends Apollo.Query<GetCurriculumByIdQuery, GetCurriculumByIdQueryVariables> {
    document = GetCurriculumByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCurriculumDocument = gql`
    mutation CreateCurriculum($input: CurriculumInput!) {
  createCurriculum(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCurriculumGQL extends Apollo.Mutation<CreateCurriculumMutation, CreateCurriculumMutationVariables> {
    document = CreateCurriculumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCurriculumDocument = gql`
    mutation UpdateCurriculum($input: UpdateCurriculumInput!) {
  updateCurriculum(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCurriculumGQL extends Apollo.Mutation<UpdateCurriculumMutation, UpdateCurriculumMutationVariables> {
    document = UpdateCurriculumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCurriculumDocument = gql`
    mutation DeleteCurriculum($id: ID!) {
  deleteCurriculum(id: $id) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCurriculumGQL extends Apollo.Mutation<DeleteCurriculumMutation, DeleteCurriculumMutationVariables> {
    document = DeleteCurriculumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllJobsDocument = gql`
    query GetAllJobs($input: JobsBoardInput!) {
  jobsBoard(input: $input) {
    data {
      items {
        ...Job
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    ${JobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllJobsGQL extends Apollo.Query<GetAllJobsQuery, GetAllJobsQueryVariables> {
    document = GetAllJobsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetJobByIdDocument = gql`
    query GetJobById($jobId: ID!) {
  JobBoard(jobId: $jobId) {
    data {
      ...Job
    }
    code
    success
    message
  }
}
    ${JobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJobByIdGQL extends Apollo.Query<GetJobByIdQuery, GetJobByIdQueryVariables> {
    document = GetJobByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($input: LoginBoardInput!) {
  loginBoard(input: $input) {
    data {
      id
      token
      firstName
      role
      securityGroup {
        ...SecurityGroup
      }
    }
    code
    success
    message
  }
}
    ${SecurityGroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendNotificationDocument = gql`
    mutation SendNotification($input: SendNotificationBoardInput!) {
  sendNotificationBoard(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendNotificationGQL extends Apollo.Mutation<SendNotificationMutation, SendNotificationMutationVariables> {
    document = SendNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllUsersDocument = gql`
    query GetAllUsers($input: UsersBoardInput!) {
  usersBoard(input: $input) {
    data {
      items {
        id
        firstName
        lastName
        email
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllUsersGQL extends Apollo.Query<GetAllUsersQuery, GetAllUsersQueryVariables> {
    document = GetAllUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadFileDocument = gql`
    mutation UploadFile($file: Upload!, $model: FileModelEnum!) {
  uploadFile(file: $file, model: $model) {
    data {
      id
      relativeDiskDestination
      name
      encoding
      mimetype
      sizeInBytes
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadFileGQL extends Apollo.Mutation<UploadFileMutation, UploadFileMutationVariables> {
    document = UploadFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllNotificationsDocument = gql`
    query GetAllNotifications($input: MyNotificationsInput!) {
  myNotifications(input: $input) {
    data {
      items {
        id
        type
        thumbnail
        enTitle
        arTitle
        enBody
        arBody
        sender {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllNotificationsGQL extends Apollo.Query<GetAllNotificationsQuery, GetAllNotificationsQueryVariables> {
    document = GetAllNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetNotificationByIdDocument = gql`
    query GetNotificationByID($notificationId: ID!) {
  myNotification(notificationId: $notificationId) {
    data {
      id
      type
      thumbnail
      enTitle
      arTitle
      enBody
      arBody
      createdAt
      updatedAt
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNotificationByIdGQL extends Apollo.Query<GetNotificationByIdQuery, GetNotificationByIdQueryVariables> {
    document = GetNotificationByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllReportsDocument = gql`
    query GetAllReports($input: ReportsFilterInput!) {
  getReports(input: $input) {
    data {
      items {
        id
        reporterId
        reportedId
        jobId
        content
        createdAt
        updatedAt
        reporter {
          id
          firstName
          bio
          lastName
          email
          school {
            name
          }
        }
        job {
          id
          grade
          gender
          status
          description
          subject {
            id
            enName
          }
          school {
            id
            name
            phone
            landlineNumber
            email
          }
        }
        reported {
          id
          firstName
          bio
          lastName
          email
          school {
            name
          }
        }
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllReportsGQL extends Apollo.Query<GetAllReportsQuery, GetAllReportsQueryVariables> {
    document = GetAllReportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReportByIdDocument = gql`
    query GetReportById($id: String!) {
  getReportById(id: $id) {
    data {
      id
      reporterId
      reportedId
      jobId
      content
      createdAt
      updatedAt
      reporter {
        id
        firstName
        bio
        lastName
        email
        school {
          name
        }
      }
      job {
        id
        grade
        gender
        status
        description
        subject {
          id
          enName
        }
        school {
          id
          name
          phone
          landlineNumber
          email
        }
      }
      reported {
        id
        firstName
        bio
        lastName
        email
        school {
          name
        }
      }
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReportByIdGQL extends Apollo.Query<GetReportByIdQuery, GetReportByIdQueryVariables> {
    document = GetReportByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllChatReportsDocument = gql`
    query GetAllChatReports($input: ChatReportFilterInput!) {
  getChatReportsByFilter(input: $input) {
    data {
      items {
        id
        userId
        owner {
          id
          firstName
          lastName
          bio
          email
          school {
            id
            name
          }
        }
        recipient {
          id
          firstName
          lastName
          bio
          email
          school {
            id
            name
          }
        }
        chatId
        status
        recipientId
        report
        lastOpened
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllChatReportsGQL extends Apollo.Query<GetAllChatReportsQuery, GetAllChatReportsQueryVariables> {
    document = GetAllChatReportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteReportDocument = gql`
    mutation DeleteReport($input: DeleteReportInputs!) {
  deleteReport(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteReportGQL extends Apollo.Mutation<DeleteReportMutation, DeleteReportMutationVariables> {
    document = DeleteReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteChatReportDocument = gql`
    mutation DeleteChatReport($input: DeleteReportInputs!) {
  deleteChatReport(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteChatReportGQL extends Apollo.Mutation<DeleteChatReportMutation, DeleteChatReportMutationVariables> {
    document = DeleteChatReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllJoinRequestsDocument = gql`
    query GetAllJoinRequests($input: ViewUsersRequestsInput!) {
  viewUsersRequestsBoard(input: $input) {
    data {
      items {
        ...User
        school {
          ...School
        }
        teacher {
          teacherId
          experience
          grades
          curriculums {
            ...Curriculum
          }
          cv
          idDocument
          certificates
          updatedAt
          createdAt
          subjects {
            id
            enName
            arName
            jobs {
              ...Job
            }
            enDescription
            arDescription
          }
        }
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    ${UserFragmentDoc}
${SchoolFragmentDoc}
${CurriculumFragmentDoc}
${JobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllJoinRequestsGQL extends Apollo.Query<GetAllJoinRequestsQuery, GetAllJoinRequestsQueryVariables> {
    document = GetAllJoinRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReplayJoinRequestDocument = gql`
    mutation ReplayJoinRequest($input: UpdateUserStatusInput!) {
  updateUserStatus(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReplayJoinRequestGQL extends Apollo.Mutation<ReplayJoinRequestMutation, ReplayJoinRequestMutationVariables> {
    document = ReplayJoinRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetJoinRequestByIdDocument = gql`
    query GetJoinRequestById($id: ID!) {
  viewUserRequestBoard(id: $id) {
    data {
      ...User
      school {
        ...School
      }
      teacher {
        teacherId
        experience
        grades
        curriculums {
          ...Curriculum
        }
        cv
        idDocument
        certificates
        updatedAt
        createdAt
        subjects {
          id
          enName
          arName
          jobs {
            ...Job
          }
          enDescription
          arDescription
        }
      }
    }
    code
    success
    message
  }
}
    ${UserFragmentDoc}
${SchoolFragmentDoc}
${CurriculumFragmentDoc}
${JobFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetJoinRequestByIdGQL extends Apollo.Query<GetJoinRequestByIdQuery, GetJoinRequestByIdQueryVariables> {
    document = GetJoinRequestByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllEditRequestsDocument = gql`
    query GetAllEditRequests($input: DocumentsUpdatesBoardInput!) {
  documentsUpdatesBoard(input: $input) {
    data {
      items {
        id
        user {
          ...User
        }
        documents
        status
        documentType
        updateReason
        rejectionReasons
        updatedAt
        createdAt
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllEditRequestsGQL extends Apollo.Query<GetAllEditRequestsQuery, GetAllEditRequestsQueryVariables> {
    document = GetAllEditRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReplayEditRequestDocument = gql`
    mutation ReplayEditRequest($input: UpdateUserDocumentsBoardInput!) {
  updateUserDocumentsBoard(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReplayEditRequestGQL extends Apollo.Mutation<ReplayEditRequestMutation, ReplayEditRequestMutationVariables> {
    document = ReplayEditRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEditRequestByIdDocument = gql`
    query GetEditRequestByID($documentUpdateId: ID!) {
  documentUpdateBoard(documentUpdateId: $documentUpdateId) {
    data {
      id
      user {
        ...User
      }
      documents
      status
      documentType
      updateReason
      rejectionReasons
      updatedAt
      createdAt
    }
    code
    success
    message
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEditRequestByIdGQL extends Apollo.Query<GetEditRequestByIdQuery, GetEditRequestByIdQueryVariables> {
    document = GetEditRequestByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllReviewsDocument = gql`
    query GetAllReviews($input: ReviewsBoardInput!) {
  reviewsBoard(input: $input) {
    data {
      items {
        ...Review
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    ${ReviewFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllReviewsGQL extends Apollo.Query<GetAllReviewsQuery, GetAllReviewsQueryVariables> {
    document = GetAllReviewsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReviewByIdDocument = gql`
    query GetReviewById($reviewId: ID!) {
  reviewBoard(reviewId: $reviewId) {
    data {
      ...Review
    }
    code
    success
    message
  }
}
    ${ReviewFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReviewByIdGQL extends Apollo.Query<GetReviewByIdQuery, GetReviewByIdQueryVariables> {
    document = GetReviewByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteReviewDocument = gql`
    mutation DeleteReview($reviewId: ID!) {
  deleteReviewBoard(reviewId: $reviewId) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteReviewGQL extends Apollo.Mutation<DeleteReviewMutation, DeleteReviewMutationVariables> {
    document = DeleteReviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllSecurityGroupsDocument = gql`
    query GetAllSecurityGroups {
  securityGroups {
    data {
      ...SecurityGroup
    }
    code
    success
    message
  }
}
    ${SecurityGroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllSecurityGroupsGQL extends Apollo.Query<GetAllSecurityGroupsQuery, GetAllSecurityGroupsQueryVariables> {
    document = GetAllSecurityGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSecurityGroupByIdDocument = gql`
    query GetSecurityGroupById($filter: SecurityGroupFilterInput!) {
  securityGroup(filter: $filter) {
    data {
      ...SecurityGroup
    }
    code
    success
    message
  }
}
    ${SecurityGroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSecurityGroupByIdGQL extends Apollo.Query<GetSecurityGroupByIdQuery, GetSecurityGroupByIdQueryVariables> {
    document = GetSecurityGroupByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSecurityGroupDocument = gql`
    mutation CreateSecurityGroup($input: CreatSecurityGroupInput!) {
  createSecurityGroup(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSecurityGroupGQL extends Apollo.Mutation<CreateSecurityGroupMutation, CreateSecurityGroupMutationVariables> {
    document = CreateSecurityGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSecurityGroupDocument = gql`
    mutation UpdateSecurityGroup($input: UpdateSecurityGroupInput!) {
  updateSecurityGroup(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSecurityGroupGQL extends Apollo.Mutation<UpdateSecurityGroupMutation, UpdateSecurityGroupMutationVariables> {
    document = UpdateSecurityGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSecurityGroupDocument = gql`
    mutation DeleteSecurityGroup($securityGrouptId: String!) {
  deleteSecurityGroup(securityGrouptId: $securityGrouptId) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSecurityGroupGQL extends Apollo.Mutation<DeleteSecurityGroupMutation, DeleteSecurityGroupMutationVariables> {
    document = DeleteSecurityGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllPermissionsDocument = gql`
    query GetAllPermissions {
  securityGroupPermissions {
    data
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllPermissionsGQL extends Apollo.Query<GetAllPermissionsQuery, GetAllPermissionsQueryVariables> {
    document = GetAllPermissionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersAssignedToRoleDocument = gql`
    query GetUsersAssignedToRole($filter: SecurityGroupFilterInput!) {
  securityGroupUsers(filter: $filter) {
    data {
      id
      firstName
      lastName
      email
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersAssignedToRoleGQL extends Apollo.Query<GetUsersAssignedToRoleQuery, GetUsersAssignedToRoleQueryVariables> {
    document = GetUsersAssignedToRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllRoleUsersDocument = gql`
    query GetAllRoleUsers($filter: SecurityGroupFilterInput!) {
  securityGroupUsers(filter: $filter) {
    data {
      ...User
    }
    code
    success
    message
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllRoleUsersGQL extends Apollo.Query<GetAllRoleUsersQuery, GetAllRoleUsersQueryVariables> {
    document = GetAllRoleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAdminDocument = gql`
    mutation CreateAdmin($input: CreateAdminInput!) {
  createAdmin(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAdminGQL extends Apollo.Mutation<CreateAdminMutation, CreateAdminMutationVariables> {
    document = CreateAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAdminDocument = gql`
    mutation UpdateAdmin($input: UpdateAdminInput!) {
  updateAdmin(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAdminGQL extends Apollo.Mutation<UpdateAdminMutation, UpdateAdminMutationVariables> {
    document = UpdateAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAdminDocument = gql`
    mutation DeleteAdmin($input: DeleteAdminInput!) {
  deleteAdmin(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAdminGQL extends Apollo.Mutation<DeleteAdminMutation, DeleteAdminMutationVariables> {
    document = DeleteAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAdminByIdDocument = gql`
    query GetAdminById($id: String!) {
  getAdminById(id: $id) {
    data {
      id
      firstName
      lastName
      email
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAdminByIdGQL extends Apollo.Query<GetAdminByIdQuery, GetAdminByIdQueryVariables> {
    document = GetAdminByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllSubjectDocument = gql`
    query GetAllSubject {
  Subjects {
    data {
      id
      enName
      arName
      enDescription
      arDescription
      createdAt
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllSubjectGQL extends Apollo.Query<GetAllSubjectQuery, GetAllSubjectQueryVariables> {
    document = GetAllSubjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSubjectByIdDocument = gql`
    query GetSubjectById($id: ID!) {
  findSubject(id: $id) {
    data {
      id
      enName
      arName
      enDescription
      arDescription
      createdAt
    }
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSubjectByIdGQL extends Apollo.Query<GetSubjectByIdQuery, GetSubjectByIdQueryVariables> {
    document = GetSubjectByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSubjectDocument = gql`
    mutation CreateSubject($input: SubjectInput!) {
  createSubject(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSubjectGQL extends Apollo.Mutation<CreateSubjectMutation, CreateSubjectMutationVariables> {
    document = CreateSubjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSubjectDocument = gql`
    mutation UpdateSubject($input: UpdateSubjectInput!) {
  updateSubject(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSubjectGQL extends Apollo.Mutation<UpdateSubjectMutation, UpdateSubjectMutationVariables> {
    document = UpdateSubjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSubjectDocument = gql`
    mutation DeleteSubject($id: ID!) {
  deleteSubject(id: $id) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSubjectGQL extends Apollo.Mutation<DeleteSubjectMutation, DeleteSubjectMutationVariables> {
    document = DeleteSubjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllSubscriptionPlansDocument = gql`
    query GetAllSubscriptionPlans($input: GetSubscriptionPlansInput!) {
  getSubscriptionPlansBoard(input: $input) {
    data {
      items {
        ...SubscriptionPlan
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    ${SubscriptionPlanFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllSubscriptionPlansGQL extends Apollo.Query<GetAllSubscriptionPlansQuery, GetAllSubscriptionPlansQueryVariables> {
    document = GetAllSubscriptionPlansDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSubscriptionPlansByIdDocument = gql`
    query GetSubscriptionPlansById($subscriptionPlanId: ID!) {
  subscriptionPlan(subscriptionPlanId: $subscriptionPlanId) {
    data {
      ...SubscriptionPlan
    }
    code
    success
    message
  }
}
    ${SubscriptionPlanFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSubscriptionPlansByIdGQL extends Apollo.Query<GetSubscriptionPlansByIdQuery, GetSubscriptionPlansByIdQueryVariables> {
    document = GetSubscriptionPlansByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSubscriptionPlanDocument = gql`
    mutation CreateSubscriptionPlan($input: CreateSubscriptionPlanBoardInput!) {
  createSubscriptionPlanBoard(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSubscriptionPlanGQL extends Apollo.Mutation<CreateSubscriptionPlanMutation, CreateSubscriptionPlanMutationVariables> {
    document = CreateSubscriptionPlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSubscriptionPlanDocument = gql`
    mutation UpdateSubscriptionPlan($input: UpdateSubscriptionPlanBoardInput!) {
  updateSubscriptionPlanBoard(input: $input) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSubscriptionPlanGQL extends Apollo.Mutation<UpdateSubscriptionPlanMutation, UpdateSubscriptionPlanMutationVariables> {
    document = UpdateSubscriptionPlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSubscriptionPlanDocument = gql`
    mutation DeleteSubscriptionPlan($subscriptionPlanId: ID!) {
  deleteSubscriptionPlanBoard(subscriptionPlanId: $subscriptionPlanId) {
    code
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSubscriptionPlanGQL extends Apollo.Mutation<DeleteSubscriptionPlanMutation, DeleteSubscriptionPlanMutationVariables> {
    document = DeleteSubscriptionPlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllTransactionDocument = gql`
    query GetAllTransaction($input: TransactionsBoardInput!) {
  transactionsBoard(input: $input) {
    data {
      items {
        ...Transaction
      }
      pageInfo {
        totalCount
        totalPages
        page
        limit
        hasNext
      }
    }
    code
    success
    message
  }
}
    ${TransactionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllTransactionGQL extends Apollo.Query<GetAllTransactionQuery, GetAllTransactionQueryVariables> {
    document = GetAllTransactionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTransactionByIdDocument = gql`
    query GetTransactionById($transactionId: ID!) {
  transactionBoard(transactionId: $transactionId) {
    data {
      ...Transaction
    }
    code
    success
    message
  }
}
    ${TransactionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTransactionByIdGQL extends Apollo.Query<GetTransactionByIdQuery, GetTransactionByIdQueryVariables> {
    document = GetTransactionByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const namedOperations = {
  Query: {
    GetAllFAQ: 'GetAllFAQ',
    GetSingleFAQ: 'GetSingleFAQ',
    GetAllTeachers: 'GetAllTeachers',
    GetAllSchools: 'GetAllSchools',
    GetTeacherById: 'GetTeacherById',
    getSchoolById: 'getSchoolById',
    findUser: 'findUser',
    GetAllAppConfigurations: 'GetAllAppConfigurations',
    GetAppConfigurationById: 'GetAppConfigurationById',
    GetAllChats: 'GetAllChats',
    chatMessage: 'chatMessage',
    GetAllContactMessages: 'GetAllContactMessages',
    GetContactMessageById: 'GetContactMessageById',
    GetAllCurriculum: 'GetAllCurriculum',
    GetCurriculumById: 'GetCurriculumById',
    GetAllJobs: 'GetAllJobs',
    GetJobById: 'GetJobById',
    GetAllUsers: 'GetAllUsers',
    GetAllNotifications: 'GetAllNotifications',
    GetNotificationByID: 'GetNotificationByID',
    GetAllReports: 'GetAllReports',
    GetReportById: 'GetReportById',
    GetAllChatReports: 'GetAllChatReports',
    GetAllJoinRequests: 'GetAllJoinRequests',
    GetJoinRequestById: 'GetJoinRequestById',
    GetAllEditRequests: 'GetAllEditRequests',
    GetEditRequestByID: 'GetEditRequestByID',
    GetAllReviews: 'GetAllReviews',
    GetReviewById: 'GetReviewById',
    GetAllSecurityGroups: 'GetAllSecurityGroups',
    GetSecurityGroupById: 'GetSecurityGroupById',
    GetAllPermissions: 'GetAllPermissions',
    GetUsersAssignedToRole: 'GetUsersAssignedToRole',
    GetAllRoleUsers: 'GetAllRoleUsers',
    GetAdminById: 'GetAdminById',
    GetAllSubject: 'GetAllSubject',
    GetSubjectById: 'GetSubjectById',
    GetAllSubscriptionPlans: 'GetAllSubscriptionPlans',
    GetSubscriptionPlansById: 'GetSubscriptionPlansById',
    GetAllTransaction: 'GetAllTransaction',
    GetTransactionById: 'GetTransactionById'
  },
  Mutation: {
    CreateFaq: 'CreateFaq',
    UpdateFaq: 'UpdateFaq',
    DeleteFaq: 'DeleteFaq',
    BlockUser: 'BlockUser',
    CreateAppConfiguration: 'CreateAppConfiguration',
    UpdateAppConfiguration: 'UpdateAppConfiguration',
    UpdateContactMessage: 'UpdateContactMessage',
    CreateCurriculum: 'CreateCurriculum',
    UpdateCurriculum: 'UpdateCurriculum',
    DeleteCurriculum: 'DeleteCurriculum',
    Login: 'Login',
    SendNotification: 'SendNotification',
    UploadFile: 'UploadFile',
    DeleteReport: 'DeleteReport',
    DeleteChatReport: 'DeleteChatReport',
    ReplayJoinRequest: 'ReplayJoinRequest',
    ReplayEditRequest: 'ReplayEditRequest',
    DeleteReview: 'DeleteReview',
    CreateSecurityGroup: 'CreateSecurityGroup',
    UpdateSecurityGroup: 'UpdateSecurityGroup',
    DeleteSecurityGroup: 'DeleteSecurityGroup',
    CreateAdmin: 'CreateAdmin',
    UpdateAdmin: 'UpdateAdmin',
    DeleteAdmin: 'DeleteAdmin',
    CreateSubject: 'CreateSubject',
    UpdateSubject: 'UpdateSubject',
    DeleteSubject: 'DeleteSubject',
    CreateSubscriptionPlan: 'CreateSubscriptionPlan',
    UpdateSubscriptionPlan: 'UpdateSubscriptionPlan',
    DeleteSubscriptionPlan: 'DeleteSubscriptionPlan'
  },
  Fragment: {
    User: 'User',
    LocationType: 'LocationType',
    SecurityGroup: 'SecurityGroup',
    LastLoginDetails: 'LastLoginDetails',
    Card: 'Card',
    Message: 'Message',
    School: 'School',
    Teacher: 'Teacher',
    Curriculum: 'Curriculum',
    Job: 'Job',
    Report: 'Report',
    Subject: 'Subject',
    Review: 'Review',
    SubscriptionPlan: 'SubscriptionPlan',
    Transaction: 'Transaction',
    PaymentUseCase: 'PaymentUseCase'
  }
}