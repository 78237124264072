import { Injectable } from "@angular/core";
import { LoginGQL } from "app/common/generated-types";

import { map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private logIn: LoginGQL
  ) {}

  login({ email, password }) {
    return this.logIn
      .mutate({ input: { email, password } })
      .pipe(
        map((response) => {
          return response.data.loginBoard?.data;
        }),
        tap((user) => {
          if (user) {
            sessionStorage.setItem("token", user.token);
          }
        })
      );
  }

  logout() {
    sessionStorage.removeItem("token");
  }
}
