import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "./auth/guards/auth.guard";
import { PagesGuard } from "./auth/guards/pages.guard";

export const routes: Routes = [
  {
    path: "pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
    // canActivate: [AuthGuard],
  },
  { path: "", redirectTo: "pages", pathMatch: "full" },
  { path: "**", redirectTo: "pages" },
];

const config: ExtraOptions = {
    useHash: false,
    scrollPositionRestoration: "enabled",
    initialNavigation: "enabled",
    relativeLinkResolution: 'legacy'
}
// {
//     useHash: false,
//     scrollPositionRestoration: "enabled",
//     initialNavigation: "enabled",
//     relativeLinkResolution: 'legacy'
// };

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
