<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"
      ><img src="../../../../assets/abjad.png" style="width: 135px"
    /></a>
  </div>
  <nb-select
    size="tiny"
    [selected]="currentTheme"
    (selectedChange)="changeTheme($event)"
    status="primary"
  >
    <nb-option *ngFor="let theme of themes" [value]="theme.value">
      {{ theme.name }}</nb-option
    >
  </nb-select>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="control-item">
      <button nbButton size="tiny" status="basic" (click)="onLogout()">
        Logout
      </button>
    </nb-action>
  </nb-actions>
</div>
