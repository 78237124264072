import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { environment } from 'environments/environment';
import { DefaultOptions } from "apollo-client";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DefaultInterceptor } from './interceptor.service';
import { AuthInterceptor } from './auth-interceptor.service';
import { APP_BASE_HREF } from '@angular/common'

export function createApollo(httpLink: HttpLink) {
  const { GRAPQHL_API_ENDPOINT } = environment;

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  };

  const result = {
    link: httpLink.create({
      uri: GRAPQHL_API_ENDPOINT,
      useMultipart: true
    }),
    cache: new InMemoryCache(),
    defaultOptions
  };

  return result;
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useValue: environment.baseHref,
    },
  ],
})
export class GraphQLModule {}
